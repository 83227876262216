// src/contexts/AuthContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionTimeLeft, setSessionTimeLeft] = useState(null); // Store the countdown
  const navigate = useNavigate();

  // Function to handle logout and redirection
  const handleLogout = useCallback(() => {
    logout(); // Perform logout
    navigate('/login'); // Redirect after logout
  }, [navigate]); // Memoize the function to prevent re-renders

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          localStorage.removeItem('token');
          handleLogout(); // Call handleLogout if token is expired
        } else {
          setUser(decoded);
          const timeLeft = decoded.exp - currentTime;
          setSessionTimeLeft(timeLeft); // Set the countdown time
        }
      } catch (e) {
        console.error('Token decoding error:', e);
        localStorage.removeItem('token');
      }
    }
    setLoading(false); // Set loading to false after checking the token

    const interval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decoded = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decoded.exp < currentTime) {
            handleLogout(); // Call handleLogout if session expired
          } else {
            // Use sessionTimeLeft for the countdown update
            setSessionTimeLeft(decoded.exp - currentTime); // Update countdown
          }
        } catch (e) {
          console.error('Token decoding error:', e);
          handleLogout(); // Logout on error
        }
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [handleLogout]); // Add handleLogout to the dependency array

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/login`,
        { email, password }
      );
      const { token } = response.data;
      localStorage.setItem('token', token);
      const decoded = jwtDecode(token);
      setUser(decoded);
      const timeLeft = decoded.exp - Date.now() / 1000;
      setSessionTimeLeft(timeLeft);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setSessionTimeLeft(null);
  };

  return (
    <AuthContext.Provider
      value={{ user, login, logout, loading, sessionTimeLeft }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
