// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { BookingProvider } from './contexts/BookingContext';
import PublicRoute from './components/routing/PublicRoute';
import Layout from './components/layout/Layout';
import Login from './pages/login/Login';
import routesConfig from './routesConfig';
import RoutesWithConfig from './components/routing/RoutesWithConfig';
import FlightSearch from './pages/flights/flightSearch/FlightSearch';
import FlightSelect from './pages/flights/flightSelect/FlightSelect';
import NotFound from './pages/notFound/NotFound';
import PassengerInfo from './pages/flights/passengerInfo/PassengerInfo';
import FlightTicket from './pages/flights/flightTicket/FlightTicket';
import Payment from './pages/flights/payment/Payment';

const App = () => {
  return (
    <Router>
      {/* Now Router is wrapping everything, including AuthProvider */}
      <AuthProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          {/* Wrapping flight-related routes with Layout and BookingProvider */}
          <Route
            path="/flights/*"
            element={
              <Layout>
                <BookingProvider>
                  <Routes>
                    <Route path="search" element={<FlightSearch />} />
                    <Route path="select" element={<FlightSelect />} />
                    <Route path="passenger-info" element={<PassengerInfo />} />
                    <Route path="ticket" element={<FlightTicket />} />
                    <Route path="payment" element={<Payment />} />
                  </Routes>
                </BookingProvider>
              </Layout>
            }
          />
          {/* Route to cover all other pages */}
          <Route
            path="*"
            element={
              <Layout>
                <RoutesWithConfig routes={routesConfig} />
              </Layout>
            }
          />
          {/* Fallback for undefined routes */}
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
