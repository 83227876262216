import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTripDetails } from '../../../services/flightService'; // Import the trip details service
import {
  FaPlaneDeparture,
  FaPlaneArrival,
  FaSuitcase,
  FaClock,
} from 'react-icons/fa';
import './FlightTicket.scss';

const FlightTicket = () => {
  const location = useLocation();
  const { uniqueID } = location.state || {};
  const [tripDetails, setTripDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uniqueID) {
      getTripDetails(uniqueID)
        .then((data) => {
          setTripDetails(data?.TripDetailsResponse?.TripDetailsResult);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching trip details:', error);
          setLoading(false);
        });
    }
  }, [uniqueID]);

  if (loading) {
    return <div className="loading">Loading trip details...</div>;
  }

  if (!tripDetails) {
    return <div className="error">Error fetching trip details.</div>;
  }

  const itinerary = tripDetails?.TravelItinerary || {};
  const passengers = itinerary?.ItineraryInfo?.CustomerInfos || [];
  const reservationItems = itinerary?.ItineraryInfo?.ReservationItems || [];
  const pricing = itinerary?.ItineraryInfo?.ItineraryPricing || {};

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="flight-ticket-advanced">
      {/* Header */}
      <div className="ticket-header">
        <div className="booking-info">
          <h1>Flight Ticket</h1>
          <h2>
            Status:{' '}
            <span className={`status ${itinerary.BookingStatus.toLowerCase()}`}>
              {itinerary.BookingStatus}
            </span>
          </h2>
          <p>PNR: {reservationItems[0]?.ReservationItem?.AirlinePNR}</p>
        </div>
        <div className="destination-info">
          <p>Destination: {itinerary.Destination}</p>
          <p>Ticket Status: {itinerary.TicketStatus}</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="ticket-body">
        {/* Passenger Information */}
        <div className="section passenger-section">
          <h3>Passenger Information</h3>
          {passengers.map((passenger, index) => (
            <div key={index} className="passenger-card">
              <div className="passenger-details">
                <p>
                  <strong>{`${passenger.CustomerInfo.PassengerTitle} ${passenger.CustomerInfo.PassengerFirstName} ${passenger.CustomerInfo.PassengerLastName}`}</strong>
                </p>
                <p>
                  <strong>eTicket Number:</strong>{' '}
                  {passenger.CustomerInfo.eTicketNumber}
                </p>
                <p>
                  <strong>Type:</strong> {passenger.CustomerInfo.PassengerType}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Flight Details */}
        <div className="section flight-details">
          <h3>Flight Details</h3>
          {reservationItems.map((item, index) => {
            // Calculate flight duration in hours and minutes
            const totalMinutes = item.ReservationItem.JourneyDuration;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return (
              <div key={index} className="flight-card">
                <div className="flight-info">
                  <div className="flight-segment">
                    <FaPlaneDeparture className="icon" />
                    <p>
                      <strong>Departure:</strong>{' '}
                      {formatDate(item.ReservationItem.DepartureDateTime)} (
                      {item.ReservationItem.DepartureAirportLocationCode})
                    </p>
                  </div>

                  <div className="flight-segment">
                    <FaPlaneArrival className="icon" />
                    <p>
                      <strong>Arrival:</strong>{' '}
                      {formatDate(item.ReservationItem.ArrivalDateTime)} (
                      {item.ReservationItem.ArrivalAirportLocationCode})
                    </p>
                  </div>

                  {/* Flight Duration Section */}
                  <div className="flight-segment">
                    <FaClock className="icon" />{' '}
                    {/* You can choose any icon for flight duration */}
                    <p>
                      <strong>Flight Duration:</strong> {hours}h {minutes}m
                    </p>
                  </div>

                  <div className="flight-segment">
                    <FaSuitcase className="icon" />
                    <p>
                      <strong>Baggage Allowance:</strong>{' '}
                      {item.ReservationItem.Baggage}
                    </p>
                  </div>

                  <p>
                    <strong>Flight Number:</strong>{' '}
                    {item.ReservationItem.FlightNumber}
                  </p>
                  <p>
                    <strong>Cabin Class:</strong>{' '}
                    {item.ReservationItem.CabinClassText}
                  </p>
                  <p>
                    <strong>Airlines:</strong>{' '}
                    {item.ReservationItem.MarketingAirlineCode}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        {/* Pricing Details */}
        <div className="section pricing-section">
          <h3>Pricing Details</h3>
          <div className="pricing-card">
            <p>
              <strong>Base Fare:</strong> {pricing?.EquiFare?.Amount}{' '}
              {pricing?.EquiFare?.CurrencyCode}
            </p>
            <p>
              <strong>Taxes:</strong> {pricing?.Tax?.Amount}{' '}
              {pricing?.Tax?.CurrencyCode}
            </p>
            <p>
              <strong>Total Fare:</strong> {pricing?.TotalFare?.Amount}{' '}
              {pricing?.TotalFare?.CurrencyCode}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightTicket;
