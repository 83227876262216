// src/data.ts
export const menu = [
  {
    id: 1,
    title: 'menu.main',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.dashboard',
        url: '/home',
        icon: 'HomeIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'menu.bookings_management',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.book_flight',
        url: '/flights/search',
        icon: 'FlightIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.view_bookings',
        url: '/home',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'menu.budgets_management',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.create_budget',
        url: '/budgets/create',
        icon: 'AttachMoneyIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      /*
      {
        id: 2,
        title: 'menu.allocate_budget',
        url: '/budgets/:id/allocate',
        icon: 'AssignmentTurnedInIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      */
      {
        id: 3,
        title: 'menu.list_budget',
        url: '/budgets',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'menu.expenses_management',
    roles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.submit_expense',
        url: '/expenses/create',
        icon: 'CreateIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.list_expenses',
        url: '/expenses',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 5,
    title: 'menu.policies',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.create_policy',
        url: '/policies/create',
        icon: 'ListAltIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      {
        id: 2,
        title: 'menu.list_policy',
        url: '/policies',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'menu.reports',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.bookings_reports',
        url: '/home',
        icon: 'DescriptionIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.budgets_reports',
        url: '/home',
        icon: 'BarChartIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 3,
        title: 'menu.expenses_reports',
        url: '/home',
        icon: 'TrendingUpIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 7,
    title: 'menu.users_management',
    roles: ['system_admin', 'corporate_admin', 'corporate_manager'],
    listItems: [
      {
        id: 1,
        title: 'menu.add_new_user',
        url: '/users/create',
        icon: 'PersonAddIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      {
        id: 2,
        title: 'menu.list_users',
        url: '/users',
        icon: 'PeopleIcon',
        roles: ['system_admin', 'corporate_admin', 'corporate_manager'],
      },
    ],
  },
  {
    id: 8,
    title: 'menu.corporates_management',
    roles: ['system_admin', 'corporate_admin'],
    listItems: [
      {
        id: 1,
        title: 'menu.add_new_corporate',
        url: '/corporates/create',
        icon: 'PersonAddIcon',
        roles: ['system_admin'],
      },
      {
        id: 2,
        title: 'menu.list_corporates',
        url: '/corporates',
        icon: 'PeopleIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
    ],
  },
  {
    id: 9,
    title: 'menu.departments_management',
    roles: ['system_admin', 'corporate_admin'],
    listItems: [
      {
        id: 1,
        title: 'menu.add_new_department',
        url: '/departments/create',
        icon: 'PersonAddIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      {
        id: 2,
        title: 'menu.list_departments',
        url: '/departments',
        icon: 'PeopleIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
    ],
  },
  {
    id: 10,
    title: 'menu.settings',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.profile',
        url: '/home',
        icon: 'PersonIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.settings',
        url: '/home',
        icon: 'SettingsIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 3,
        title: 'menu.logout',
        url: '/home',
        icon: 'ExitToAppIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
];

// src/data.ts

export const countryCodes = [
  { code: '+93', name: 'Afghanistan' },
  { code: '+355', name: 'Albania' },
  { code: '+213', name: 'Algeria' },
  { code: '+1684', name: 'American Samoa' },
  { code: '+376', name: 'Andorra' },
  { code: '+244', name: 'Angola' },
  { code: '+1264', name: 'Anguilla' },
  { code: '+672', name: 'Antarctica' },
  { code: '+1268', name: 'Antigua and Barbuda' },
  { code: '+54', name: 'Argentina' },
  { code: '+374', name: 'Armenia' },
  { code: '+297', name: 'Aruba' },
  { code: '+61', name: 'Australia' },
  { code: '+43', name: 'Austria' },
  { code: '+994', name: 'Azerbaijan' },
  { code: '+1242', name: 'Bahamas' },
  { code: '+973', name: 'Bahrain' },
  { code: '+880', name: 'Bangladesh' },
  { code: '+1246', name: 'Barbados' },
  { code: '+375', name: 'Belarus' },
  { code: '+32', name: 'Belgium' },
  { code: '+501', name: 'Belize' },
  { code: '+229', name: 'Benin' },
  { code: '+1441', name: 'Bermuda' },
  { code: '+975', name: 'Bhutan' },
  { code: '+591', name: 'Bolivia' },
  { code: '+387', name: 'Bosnia and Herzegovina' },
  { code: '+267', name: 'Botswana' },
  { code: '+55', name: 'Brazil' },
  { code: '+246', name: 'British Indian Ocean Territory' },
  { code: '+1284', name: 'British Virgin Islands' },
  { code: '+673', name: 'Brunei' },
  { code: '+359', name: 'Bulgaria' },
  { code: '+226', name: 'Burkina Faso' },
  { code: '+257', name: 'Burundi' },
  { code: '+855', name: 'Cambodia' },
  { code: '+237', name: 'Cameroon' },
  { code: '+1', name: 'Canada' },
  { code: '+238', name: 'Cape Verde' },
  { code: '+1345', name: 'Cayman Islands' },
  { code: '+236', name: 'Central African Republic' },
  { code: '+235', name: 'Chad' },
  { code: '+56', name: 'Chile' },
  { code: '+86', name: 'China' },
  { code: '+61', name: 'Christmas Island' },
  { code: '+61', name: 'Cocos Islands' },
  { code: '+57', name: 'Colombia' },
  { code: '+269', name: 'Comoros' },
  { code: '+682', name: 'Cook Islands' },
  { code: '+506', name: 'Costa Rica' },
  { code: '+385', name: 'Croatia' },
  { code: '+53', name: 'Cuba' },
  { code: '+599', name: 'Curacao' },
  { code: '+357', name: 'Cyprus' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+243', name: 'Democratic Republic of the Congo' },
  { code: '+45', name: 'Denmark' },
  { code: '+253', name: 'Djibouti' },
  { code: '+1767', name: 'Dominica' },
  { code: '+1809', name: 'Dominican Republic' },
  { code: '+593', name: 'Ecuador' },
  { code: '+20', name: 'Egypt' },
  { code: '+503', name: 'El Salvador' },
  { code: '+240', name: 'Equatorial Guinea' },
  { code: '+291', name: 'Eritrea' },
  { code: '+372', name: 'Estonia' },
  { code: '+251', name: 'Ethiopia' },
  { code: '+500', name: 'Falkland Islands' },
  { code: '+298', name: 'Faroe Islands' },
  { code: '+679', name: 'Fiji' },
  { code: '+358', name: 'Finland' },
  { code: '+33', name: 'France' },
  { code: '+689', name: 'French Polynesia' },
  { code: '+241', name: 'Gabon' },
  { code: '+220', name: 'Gambia' },
  { code: '+995', name: 'Georgia' },
  { code: '+49', name: 'Germany' },
  { code: '+233', name: 'Ghana' },
  { code: '+350', name: 'Gibraltar' },
  { code: '+30', name: 'Greece' },
  { code: '+299', name: 'Greenland' },
  { code: '+1473', name: 'Grenada' },
  { code: '+1671', name: 'Guam' },
  { code: '+502', name: 'Guatemala' },
  { code: '+224', name: 'Guinea' },
  { code: '+245', name: 'Guinea-Bissau' },
  { code: '+592', name: 'Guyana' },
  { code: '+509', name: 'Haiti' },
  { code: '+504', name: 'Honduras' },
  { code: '+852', name: 'Hong Kong' },
  { code: '+36', name: 'Hungary' },
  { code: '+354', name: 'Iceland' },
  { code: '+91', name: 'India' },
  { code: '+62', name: 'Indonesia' },
  { code: '+98', name: 'Iran' },
  { code: '+964', name: 'Iraq' },
  { code: '+353', name: 'Ireland' },
  { code: '+972', name: 'Israel' },
  { code: '+39', name: 'Italy' },
  { code: '+225', name: 'Ivory Coast' },
  { code: '+1876', name: 'Jamaica' },
  { code: '+81', name: 'Japan' },
  { code: '+962', name: 'Jordan' },
  { code: '+7', name: 'Kazakhstan' },
  { code: '+254', name: 'Kenya' },
  { code: '+686', name: 'Kiribati' },
  { code: '+383', name: 'Kosovo' },
  { code: '+965', name: 'Kuwait' },
  { code: '+996', name: 'Kyrgyzstan' },
  { code: '+856', name: 'Laos' },
  { code: '+371', name: 'Latvia' },
  { code: '+961', name: 'Lebanon' },
  { code: '+266', name: 'Lesotho' },
  { code: '+231', name: 'Liberia' },
  { code: '+218', name: 'Libya' },
  { code: '+423', name: 'Liechtenstein' },
  { code: '+370', name: 'Lithuania' },
  { code: '+352', name: 'Luxembourg' },
  { code: '+853', name: 'Macau' },
  { code: '+389', name: 'Macedonia' },
  { code: '+261', name: 'Madagascar' },
  { code: '+265', name: 'Malawi' },
  { code: '+60', name: 'Malaysia' },
  { code: '+960', name: 'Maldives' },
  { code: '+223', name: 'Mali' },
  { code: '+356', name: 'Malta' },
  { code: '+692', name: 'Marshall Islands' },
  { code: '+596', name: 'Martinique' },
  { code: '+222', name: 'Mauritania' },
  { code: '+230', name: 'Mauritius' },
  { code: '+52', name: 'Mexico' },
  { code: '+691', name: 'Micronesia' },
  { code: '+373', name: 'Moldova' },
  { code: '+377', name: 'Monaco' },
  { code: '+976', name: 'Mongolia' },
  { code: '+382', name: 'Montenegro' },
  { code: '+1664', name: 'Montserrat' },
  { code: '+212', name: 'Morocco' },
  { code: '+258', name: 'Mozambique' },
  { code: '+95', name: 'Myanmar' },
  { code: '+264', name: 'Namibia' },
  { code: '+674', name: 'Nauru' },
  { code: '+977', name: 'Nepal' },
  { code: '+31', name: 'Netherlands' },
  { code: '+599', name: 'Netherlands Antilles' },
  { code: '+687', name: 'New Caledonia' },
  { code: '+64', name: 'New Zealand' },
  { code: '+505', name: 'Nicaragua' },
  { code: '+227', name: 'Niger' },
  { code: '+234', name: 'Nigeria' },
  { code: '+683', name: 'Niue' },
  { code: '+850', name: 'North Korea' },
  { code: '+1670', name: 'Northern Mariana Islands' },
  { code: '+47', name: 'Norway' },
  { code: '+968', name: 'Oman' },
  { code: '+92', name: 'Pakistan' },
  { code: '+680', name: 'Palau' },
  { code: '+970', name: 'Palestine' },
  { code: '+507', name: 'Panama' },
  { code: '+675', name: 'Papua New Guinea' },
  { code: '+595', name: 'Paraguay' },
  { code: '+51', name: 'Peru' },
  { code: '+63', name: 'Philippines' },
  { code: '+48', name: 'Poland' },
  { code: '+351', name: 'Portugal' },
  { code: '+1', name: 'Puerto Rico' },
  { code: '+974', name: 'Qatar' },
  { code: '+242', name: 'Republic of the Congo' },
  { code: '+40', name: 'Romania' },
  { code: '+7', name: 'Russia' },
  { code: '+250', name: 'Rwanda' },
  { code: '+262', name: 'Réunion' },
  { code: '+290', name: 'Saint Helena' },
  { code: '+1869', name: 'Saint Kitts and Nevis' },
  { code: '+1758', name: 'Saint Lucia' },
  { code: '+508', name: 'Saint Pierre and Miquelon' },
  { code: '+1784', name: 'Saint Vincent and the Grenadines' },
  { code: '+685', name: 'Samoa' },
  { code: '+378', name: 'San Marino' },
  { code: '+239', name: 'Sao Tome and Principe' },
  { code: '+966', name: 'Saudi Arabia' },
  { code: '+221', name: 'Senegal' },
  { code: '+381', name: 'Serbia' },
  { code: '+248', name: 'Seychelles' },
  { code: '+232', name: 'Sierra Leone' },
  { code: '+65', name: 'Singapore' },
  { code: '+1721', name: 'Sint Maarten' },
  { code: '+421', name: 'Slovakia' },
  { code: '+386', name: 'Slovenia' },
  { code: '+677', name: 'Solomon Islands' },
  { code: '+252', name: 'Somalia' },
  { code: '+27', name: 'South Africa' },
  { code: '+82', name: 'South Korea' },
  { code: '+211', name: 'South Sudan' },
  { code: '+34', name: 'Spain' },
  { code: '+94', name: 'Sri Lanka' },
  { code: '+249', name: 'Sudan' },
  { code: '+597', name: 'Suriname' },
  { code: '+268', name: 'Swaziland' },
  { code: '+46', name: 'Sweden' },
  { code: '+41', name: 'Switzerland' },
  { code: '+963', name: 'Syria' },
  { code: '+886', name: 'Taiwan' },
  { code: '+992', name: 'Tajikistan' },
  { code: '+255', name: 'Tanzania' },
  { code: '+66', name: 'Thailand' },
  { code: '+670', name: 'Timor-Leste' },
  { code: '+228', name: 'Togo' },
  { code: '+690', name: 'Tokelau' },
  { code: '+676', name: 'Tonga' },
  { code: '+1868', name: 'Trinidad and Tobago' },
  { code: '+216', name: 'Tunisia' },
  { code: '+90', name: 'Turkey' },
  { code: '+993', name: 'Turkmenistan' },
  { code: '+1649', name: 'Turks and Caicos Islands' },
  { code: '+688', name: 'Tuvalu' },
  { code: '+256', name: 'Uganda' },
  { code: '+380', name: 'Ukraine' },
  { code: '+971', name: 'United Arab Emirates' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+1', name: 'United States' },
  { code: '+598', name: 'Uruguay' },
  { code: '+998', name: 'Uzbekistan' },
  { code: '+678', name: 'Vanuatu' },
  { code: '+379', name: 'Vatican City' },
  { code: '+58', name: 'Venezuela' },
  { code: '+84', name: 'Vietnam' },
  { code: '+681', name: 'Wallis and Futuna' },
  { code: '+967', name: 'Yemen' },
  { code: '+260', name: 'Zambia' },
  { code: '+263', name: 'Zimbabwe' },
];

// src/data.ts
export const nationalities = [
  { code: 'AF', name: 'Afghan' },
  { code: 'AL', name: 'Albanian' },
  { code: 'DZ', name: 'Algerian' },
  { code: 'AS', name: 'American Samoan' },
  { code: 'AD', name: 'Andorran' },
  { code: 'AO', name: 'Angolan' },
  { code: 'AR', name: 'Argentine' },
  { code: 'AM', name: 'Armenian' },
  { code: 'AU', name: 'Australian' },
  { code: 'AT', name: 'Austrian' },
  { code: 'AZ', name: 'Azerbaijani' },
  { code: 'BH', name: 'Bahraini' },
  { code: 'BD', name: 'Bangladeshi' },
  { code: 'BB', name: 'Barbadian' },
  { code: 'BY', name: 'Belarusian' },
  { code: 'BE', name: 'Belgian' },
  { code: 'BZ', name: 'Belizean' },
  { code: 'BJ', name: 'Beninese' },
  { code: 'BT', name: 'Bhutanese' },
  { code: 'BO', name: 'Bolivian' },
  { code: 'BA', name: 'Bosnian' },
  { code: 'BW', name: 'Botswanan' },
  { code: 'BR', name: 'Brazilian' },
  { code: 'BN', name: 'Bruneian' },
  { code: 'BG', name: 'Bulgarian' },
  { code: 'BF', name: 'Burkinabe' },
  { code: 'BI', name: 'Burundian' },
  { code: 'KH', name: 'Cambodian' },
  { code: 'CM', name: 'Cameroonian' },
  { code: 'CA', name: 'Canadian' },
  { code: 'TD', name: 'Chadian' },
  { code: 'CL', name: 'Chilean' },
  { code: 'CN', name: 'Chinese' },
  { code: 'CO', name: 'Colombian' },
  { code: 'CG', name: 'Congolese' },
  { code: 'CR', name: 'Costa Rican' },
  { code: 'HR', name: 'Croatian' },
  { code: 'CU', name: 'Cuban' },
  { code: 'CY', name: 'Cypriot' },
  { code: 'CZ', name: 'Czech' },
  { code: 'DK', name: 'Danish' },
  { code: 'DJ', name: 'Djiboutian' },
  { code: 'DM', name: 'Dominican' },
  { code: 'EC', name: 'Ecuadorian' },
  { code: 'EG', name: 'Egyptian' },
  { code: 'SV', name: 'Salvadoran' },
  { code: 'GQ', name: 'Equatorial Guinean' },
  { code: 'EE', name: 'Estonian' },
  { code: 'ET', name: 'Ethiopian' },
  { code: 'FI', name: 'Finnish' },
  { code: 'FR', name: 'French' },
  { code: 'DE', name: 'German' },
  { code: 'GH', name: 'Ghanaian' },
  { code: 'GR', name: 'Greek' },
  { code: 'GT', name: 'Guatemalan' },
  { code: 'GN', name: 'Guinean' },
  { code: 'GY', name: 'Guyanese' },
  { code: 'HT', name: 'Haitian' },
  { code: 'HN', name: 'Honduran' },
  { code: 'HU', name: 'Hungarian' },
  { code: 'IS', name: 'Icelandic' },
  { code: 'IN', name: 'Indian' },
  { code: 'ID', name: 'Indonesian' },
  { code: 'IR', name: 'Iranian' },
  { code: 'IQ', name: 'Iraqi' },
  { code: 'IE', name: 'Irish' },
  { code: 'IL', name: 'Israeli' },
  { code: 'IT', name: 'Italian' },
  { code: 'CI', name: 'Ivorian' },
  { code: 'JM', name: 'Jamaican' },
  { code: 'JP', name: 'Japanese' },
  { code: 'JO', name: 'Jordanian' },
  { code: 'KZ', name: 'Kazakh' },
  { code: 'KE', name: 'Kenyan' },
  { code: 'KW', name: 'Kuwaiti' },
  { code: 'KG', name: 'Kyrgyz' },
  { code: 'LA', name: 'Laotian' },
  { code: 'LV', name: 'Latvian' },
  { code: 'LB', name: 'Lebanese' },
  { code: 'LS', name: 'Basotho' },
  { code: 'LR', name: 'Liberian' },
  { code: 'LY', name: 'Libyan' },
  { code: 'LT', name: 'Lithuanian' },
  { code: 'LU', name: 'Luxembourgish' },
  { code: 'MG', name: 'Malagasy' },
  { code: 'MW', name: 'Malawian' },
  { code: 'MY', name: 'Malaysian' },
  { code: 'MV', name: 'Maldivian' },
  { code: 'ML', name: 'Malian' },
  { code: 'MT', name: 'Maltese' },
  { code: 'MU', name: 'Mauritian' },
  { code: 'MX', name: 'Mexican' },
  { code: 'MD', name: 'Moldovan' },
  { code: 'MC', name: 'Monacan' },
  { code: 'MN', name: 'Mongolian' },
  { code: 'ME', name: 'Montenegrin' },
  { code: 'MA', name: 'Moroccan' },
  { code: 'MZ', name: 'Mozambican' },
  { code: 'MM', name: 'Burmese' },
  { code: 'NA', name: 'Namibian' },
  { code: 'NP', name: 'Nepalese' },
  { code: 'NL', name: 'Dutch' },
  { code: 'NZ', name: 'New Zealander' },
  { code: 'NG', name: 'Nigerian' },
  { code: 'NO', name: 'Norwegian' },
  { code: 'OM', name: 'Omani' },
  { code: 'PK', name: 'Pakistani' },
  { code: 'PA', name: 'Panamanian' },
  { code: 'PG', name: 'Papua New Guinean' },
  { code: 'PE', name: 'Peruvian' },
  { code: 'PH', name: 'Filipino' },
  { code: 'PL', name: 'Polish' },
  { code: 'PT', name: 'Portuguese' },
  { code: 'QA', name: 'Qatari' },
  { code: 'RO', name: 'Romanian' },
  { code: 'RU', name: 'Russian' },
  { code: 'RW', name: 'Rwandan' },
  { code: 'SA', name: 'Saudi' },
  { code: 'SN', name: 'Senegalese' },
  { code: 'RS', name: 'Serbian' },
  { code: 'SG', name: 'Singaporean' },
  { code: 'SK', name: 'Slovak' },
  { code: 'SI', name: 'Slovenian' },
  { code: 'ZA', name: 'South African' },
  { code: 'KR', name: 'South Korean' },
  { code: 'ES', name: 'Spanish' },
  { code: 'LK', name: 'Sri Lankan' },
  { code: 'SD', name: 'Sudanese' },
  { code: 'SE', name: 'Swedish' },
  { code: 'CH', name: 'Swiss' },
  { code: 'SY', name: 'Syrian' },
  { code: 'TW', name: 'Taiwanese' },
  { code: 'TZ', name: 'Tanzanian' },
  { code: 'TH', name: 'Thai' },
  { code: 'TN', name: 'Tunisian' },
  { code: 'TR', name: 'Turkish' },
  { code: 'UG', name: 'Ugandan' },
  { code: 'UA', name: 'Ukrainian' },
  { code: 'AE', name: 'Emirati' },
  { code: 'GB', name: 'British' },
  { code: 'US', name: 'American' },
  { code: 'UY', name: 'Uruguayan' },
  { code: 'UZ', name: 'Uzbek' },
  { code: 'VE', name: 'Venezuelan' },
  { code: 'VN', name: 'Vietnamese' },
  { code: 'ZM', name: 'Zambian' },
  { code: 'ZW', name: 'Zimbabwean' },
];
