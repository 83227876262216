import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './JourneyTypeDropdown.scss';

const JourneyTypeDropdown = ({ selectedOption, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { label: 'One Way', value: 'OneWay' },
    { label: 'Return', value: 'Return' },
    { label: 'Multi-City', value: 'Multi-city' },
  ];

  const handleOptionChange = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        className="dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}
      >
        {options.find((option) => option.value === selectedOption)?.label ||
          'Select Journey Type'}
        <ExpandMoreIcon className="expand-icon" />
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option) => (
            <label key={option.value} className="dropdown-item">
              <input
                type="radio"
                name="journeyType"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleOptionChange(option.value)}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default JourneyTypeDropdown;
