import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  Collapse,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Flight, Info } from '@mui/icons-material';
import { useBooking } from '../../../contexts/BookingContext';
import Swal from 'sweetalert2'; // Import SweetAlert
import './FlightSelect.scss';
import { searchFlights, fetchFareRules } from '../../../services/flightService';

const FlightSelect = () => {
  const navigate = useNavigate();
  const { bookingData, updateBookingData, resetFlightResults } = useBooking();
  const [loading, setLoading] = useState(!bookingData.flightResults);
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const [expanded, setExpanded] = useState(null); // Handle collapsible state for specific flight
  const [loadingFareRules, setLoadingFareRules] = useState(false);

  // State for fare rules dialog
  const [fareRulesOpen, setFareRulesOpen] = useState(false);
  const [selectedFlightFareRules, setSelectedFlightFareRules] = useState(null);

  // Function to handle closing the fare rules dialog
  const handleFareRulesClose = () => {
    setFareRulesOpen(false);
    setSelectedFlightFareRules(null);
  };

  const handleFareRulesClick = async (flight) => {
    try {
      // Set loading to true before making the API call
      setLoadingFareRules(true);
      setFareRulesOpen(true); // Open the dialog immediately to show the loading spinner

      const sessionId = bookingData?.session_id;
      const fareSourceCode =
        flight.FareItinerary.AirItineraryFareInfo.FareSourceCode;

      // Validate that sessionId exists
      if (!sessionId) {
        throw new Error('Session ID is missing.');
      }

      // Call the backend API to fetch fare rules
      const response = await fetchFareRules(sessionId, fareSourceCode);

      const fareRules =
        response?.FareRules1_1Response?.FareRules1_1Result?.FareRules;

      if (!fareRules || !Array.isArray(fareRules)) {
        throw new Error('Invalid fare rules received from the backend.');
      }

      setSelectedFlightFareRules(fareRules);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error Occurred',
        text: error.message || 'Failed to fetch fare rules. Please try again.',
      });
    } finally {
      setLoadingFareRules(false); // Turn off the loading indicator
    }
  };

  useEffect(() => {
    const fetchFlights = async () => {
      if (
        (!bookingData.flightResults && bookingData.searchCriteria) ||
        hasRefreshed
      ) {
        setLoading(true);
        try {
          const response = await searchFlights(bookingData.searchCriteria);

          // Check if there is an error in the response
          if (response.error) {
            Swal.fire({
              icon: 'error',
              title: 'Error Occurred',
              text: response.error, // Display the exact error message from the backend
              confirmButtonText: 'Go Back to Search',
            }).then(() => {
              navigate('/flights/search'); // Navigate back to the search page
            });
            setLoading(false);
            return;
          }

          const sessionId = response?.AirSearchResponse?.session_id;
          const flightResults =
            response?.AirSearchResponse?.AirSearchResult?.FareItineraries;

          if (!sessionId || !flightResults) {
            setLoading(false);
            return;
          }

          await updateBookingData({
            flightResults: flightResults,
            session_id: sessionId,
          });

          setLoading(false);
          setHasRefreshed(false);
        } catch (error) {
          setLoading(false);
        }
      } else if (!bookingData.flightResults && !bookingData.searchCriteria) {
        navigate('/flights/search');
      }
    };

    fetchFlights();
  }, [
    bookingData.flightResults,
    bookingData.searchCriteria,
    updateBookingData,
    navigate,
    hasRefreshed,
  ]);

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'reload') {
      resetFlightResults();
      setHasRefreshed(true);
    }
  }, [resetFlightResults]);

  const handleSelectFlight = (flight) => {
    const flightId =
      flight?.FareItinerary?.AirItineraryFareInfo?.FareSourceCode;
    const sessionId = bookingData?.session_id;
    const fareSourceCode =
      flight?.FareItinerary?.AirItineraryFareInfo?.FareSourceCode;

    if (!flightId || !sessionId || !fareSourceCode) {
      return;
    }

    updateBookingData({
      selectedFlight: flight,
      session_id: sessionId,
      fare_source_code: fareSourceCode,
    });

    navigate('/flights/passenger-info');
  };

  const handleExpandClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const flights = bookingData.flightResults || [];

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <Typography variant="h6" className="loading-text">
          Loading flight results...
        </Typography>
      </div>
    );
  }

  return (
    <div className="flightSelectContainer">
      {flights.map((flight, index) => {
        const isOneWay = flight.FareItinerary.DirectionInd === 'OneWay';
        const isMultiCity = flight.FareItinerary.DirectionInd === 'Circle';
        return (
          <div className="flightCardContainer" key={index}>
            <div className="flightCard">
              <div className="flightInfo">
                {/* Multi-city journey info */}
                {isMultiCity ? (
                  flight.FareItinerary?.OriginDestinationOptions.map(
                    (leg, legIndex) => (
                      <div key={legIndex} className="multiCityLeg">
                        <h3>
                          {'('}
                          {leg.TotalStops === 0
                            ? 'Direct'
                            : `${leg.TotalStops} Stops`}
                          {')'}
                        </h3>
                        {leg.OriginDestinationOption.map(
                          (segment, segmentIndex) => (
                            <div key={segmentIndex} className="flightSegment">
                              <div className="airline-logo-container">
                                <img
                                  src={`/airlines/${segment.FlightSegment?.MarketingAirlineCode}.png`}
                                  alt="Airline Logo"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.style.display = 'none';
                                    e.target.parentNode.querySelector(
                                      '.airline-code'
                                    ).style.display = 'block';
                                  }}
                                />
                                <div
                                  className="airline-code"
                                  style={{ display: 'none' }}
                                >
                                  {segment.FlightSegment?.MarketingAirlineCode}
                                </div>
                              </div>

                              <div className="departureInfo">
                                <div className="departureTime">
                                  {new Date(
                                    segment.FlightSegment?.DepartureDateTime
                                  ).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false,
                                  })}
                                </div>
                                <div className="departureAirportCode">
                                  {
                                    segment.FlightSegment
                                      ?.DepartureAirportLocationCode
                                  }
                                </div>
                              </div>

                              <div className="metaInfo">
                                <div className="flightDuration">
                                  {Math.floor(
                                    segment.FlightSegment?.JourneyDuration / 60
                                  )}
                                  h{' '}
                                  {segment.FlightSegment?.JourneyDuration % 60}m
                                </div>
                                <div className="connectingLine">
                                  <Flight className="flight-icon" />
                                </div>
                              </div>

                              <div className="arrivalInfo">
                                <div className="arrivalTime">
                                  {new Date(
                                    segment.FlightSegment?.ArrivalDateTime
                                  ).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false,
                                  })}
                                </div>
                                <div className="arrivalAirportCode">
                                  {
                                    segment.FlightSegment
                                      ?.ArrivalAirportLocationCode
                                  }
                                </div>
                              </div>

                              <div className="flightClassInfo">
                                <div className="flightClassTitle">Class</div>
                                <div className="flightClassText">
                                  {segment.FlightSegment?.CabinClassText}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )
                  )
                ) : (
                  // One-way flight info (existing logic)
                  <div className="oneWay">
                    <h3>
                      Outbound Flight {'( '}
                      {flight.FareItinerary?.OriginDestinationOptions[0]
                        ?.TotalStops === 0
                        ? 'Direct'
                        : `${flight.FareItinerary?.OriginDestinationOptions[0]?.TotalStops} Stops`}{' '}
                      {' )'}
                    </h3>
                    {flight.FareItinerary?.OriginDestinationOptions[0]
                      ?.OriginDestinationOption.length > 1 ? (
                      flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption.map(
                        (segment, index) => (
                          <div key={index} className="flightSegment">
                            <div className="airline-logo-container">
                              <img
                                src={`/airlines/${segment.FlightSegment?.MarketingAirlineCode}.png`}
                                alt="Airline Logo"
                                onError={(e) => {
                                  e.target.onerror = null; // Prevents infinite looping if the fallback image also fails
                                  e.target.style.display = 'none'; // Hide the image
                                  e.target.parentNode.querySelector(
                                    '.airline-code'
                                  ).style.display = 'block'; // Show airline code
                                }}
                              />
                              <div
                                className="airline-code"
                                style={{ display: 'none' }}
                              >
                                {segment.FlightSegment?.MarketingAirlineCode}
                              </div>
                            </div>

                            <div className="departureInfo">
                              <div className="departureTime">
                                {new Date(
                                  segment.FlightSegment?.DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                })}
                              </div>
                              <div className="departureAirportCode">
                                {
                                  segment.FlightSegment
                                    ?.DepartureAirportLocationCode
                                }
                              </div>
                            </div>

                            <div className="metaInfo">
                              <div className="flightDuration">
                                {Math.floor(
                                  segment.FlightSegment?.JourneyDuration / 60
                                )}
                                h {segment.FlightSegment?.JourneyDuration % 60}m
                              </div>
                              <div className="connectingLine">
                                {/*<
                                  div className="stop-dot"></div>
                                  */}
                                <Flight className="flight-icon" />
                              </div>
                              {/*
                            <div className="stops">
                              {flight.FareItinerary?.OriginDestinationOptions[0]
                                ?.TotalStops === 0
                                ? 'Direct'
                                : `${flight.FareItinerary?.OriginDestinationOptions[0]?.TotalStops} Stops`}
                            </div>
                            */}
                            </div>

                            <div className="arrivalInfo">
                              <div className="arrivalTime">
                                {new Date(
                                  segment.FlightSegment?.ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                })}
                              </div>
                              <div className="arrivalAirportCode">
                                {
                                  segment.FlightSegment
                                    ?.ArrivalAirportLocationCode
                                }
                              </div>
                            </div>

                            <div className="flightClassInfo">
                              <div className="flightClassTitle">Class</div>
                              <div className="flightClassText">
                                {segment.FlightSegment?.CabinClassText}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      // Direct flight (same as current structure)
                      <div className="flightSegment">
                        <div className="airline-logo-container">
                          <img
                            src={`/airlines/${flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.MarketingAirlineCode}.png`}
                            alt="Airline Logo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.style.display = 'none';
                              e.target.parentNode.querySelector(
                                '.airline-code'
                              ).style.display = 'block';
                            }}
                          />
                          <div
                            className="airline-code"
                            style={{ display: 'none' }}
                          >
                            {
                              flight.FareItinerary?.OriginDestinationOptions[0]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.MarketingAirlineCode
                            }
                          </div>
                        </div>

                        <div className="departureInfo">
                          <div className="departureTime">
                            {new Date(
                              flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })}
                          </div>
                          <div className="departureAirportCode">
                            {
                              flight.FareItinerary?.OriginDestinationOptions[0]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.DepartureAirportLocationCode
                            }
                          </div>
                        </div>

                        <div className="metaInfo">
                          <div className="flightDuration">
                            {Math.floor(
                              flight.FareItinerary?.OriginDestinationOptions[0]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.JourneyDuration / 60
                            )}
                            h{' '}
                            {flight.FareItinerary?.OriginDestinationOptions[0]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.JourneyDuration % 60}
                            m
                          </div>
                          <div className="connectingLine">
                            <Flight className="flight-icon" />
                          </div>
                          {/*<div className="stops">
                          {flight.FareItinerary?.OriginDestinationOptions[0]
                            ?.TotalStops === 0
                            ? 'Direct'
                            : `${flight.FareItinerary?.OriginDestinationOptions[0]?.TotalStops} Stops`}
                        </div>
                        */}
                        </div>

                        <div className="arrivalInfo">
                          <div className="arrivalTime">
                            {new Date(
                              flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })}
                          </div>
                          <div className="arrivalAirportCode">
                            {
                              flight.FareItinerary?.OriginDestinationOptions[0]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.ArrivalAirportLocationCode
                            }
                          </div>
                        </div>

                        <div className="flightClassInfo">
                          <div className="flightClassTitle">Class</div>
                          <div className="flightClassText">
                            {
                              flight.FareItinerary?.OriginDestinationOptions[0]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.CabinClassText
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Return flight info (if applicable) */}
                {/* Return flight info (if applicable) */}
                {!isOneWay && !isMultiCity && (
                  <div className="return">
                    <h3>
                      Inbound Flight {'( '}
                      {flight.FareItinerary?.OriginDestinationOptions[1]
                        ?.TotalStops === 0
                        ? 'Direct'
                        : `${flight.FareItinerary?.OriginDestinationOptions[1]?.TotalStops} Stops`}{' '}
                      {' )'}
                    </h3>
                    {flight.FareItinerary?.OriginDestinationOptions[1]
                      ?.OriginDestinationOption.length > 1 ? (
                      flight.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption.map(
                        (segment, index) => (
                          <div key={index} className="returnFlightSegment">
                            <div className="airline-logo-container">
                              <img
                                src={`/airlines/${segment.FlightSegment?.MarketingAirlineCode}.png`}
                                alt="Airline Logo"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.display = 'none';
                                  e.target.parentNode.querySelector(
                                    '.airline-code'
                                  ).style.display = 'block';
                                }}
                              />
                              <div
                                className="airline-code"
                                style={{ display: 'none' }}
                              >
                                {segment.FlightSegment?.MarketingAirlineCode}
                              </div>
                            </div>

                            <div className="returnDepartureInfo">
                              <div className="returnDepartureTime">
                                {new Date(
                                  segment.FlightSegment?.DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                })}
                              </div>
                              <div className="returnDepartureAirportCode">
                                {
                                  segment.FlightSegment
                                    ?.DepartureAirportLocationCode
                                }
                              </div>
                            </div>

                            <div className="metaInfo">
                              <div className="flightDuration">
                                {Math.floor(
                                  segment.FlightSegment?.JourneyDuration / 60
                                )}
                                h {segment.FlightSegment?.JourneyDuration % 60}m
                              </div>
                              <div className="connectingLine">
                                <Flight className="flight-icon" />
                              </div>
                              {/*
                              <div className="stops">
                                {flight.FareItinerary
                                  ?.OriginDestinationOptions[1]?.TotalStops ===
                                0
                                  ? 'Direct'
                                  : `${flight.FareItinerary?.OriginDestinationOptions[1]?.TotalStops} Stops`}
                              </div>
                              */}
                            </div>

                            <div className="returnArrivalInfo">
                              <div className="returnArrivalTime">
                                {new Date(
                                  segment.FlightSegment?.ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                })}
                              </div>
                              <div className="returnArrivalAirportCode">
                                {
                                  segment.FlightSegment
                                    ?.ArrivalAirportLocationCode
                                }
                              </div>
                            </div>

                            <div className="returnFlightClassInfo">
                              <div className="returnFlightClassTitle">
                                Class
                              </div>
                              <div className="returnFlightClassText">
                                {segment.FlightSegment?.CabinClassText}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      // Direct return flight (same as current structure)
                      <div className="returnFlightSegment">
                        <div className="airline-logo-container">
                          <img
                            src={`/airlines/${flight.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.MarketingAirlineCode}.png`}
                            alt="Airline Logo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.style.display = 'none';
                              e.target.parentNode.querySelector(
                                '.airline-code'
                              ).style.display = 'block';
                            }}
                          />
                          <div
                            className="airline-code"
                            style={{ display: 'none' }}
                          >
                            {
                              flight.FareItinerary?.OriginDestinationOptions[1]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.MarketingAirlineCode
                            }
                          </div>
                        </div>

                        <div className="returnDepartureInfo">
                          <div className="returnDepartureTime">
                            {new Date(
                              flight.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })}
                          </div>
                          <div className="returnDepartureAirportCode">
                            {
                              flight.FareItinerary?.OriginDestinationOptions[1]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.DepartureAirportLocationCode
                            }
                          </div>
                        </div>

                        <div className="metaInfo">
                          <div className="flightDuration">
                            {Math.floor(
                              flight.FareItinerary?.OriginDestinationOptions[1]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.JourneyDuration / 60
                            )}
                            h{' '}
                            {flight.FareItinerary?.OriginDestinationOptions[1]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.JourneyDuration % 60}
                            m
                          </div>
                          <div className="connectingLine">
                            <Flight className="flight-icon" />
                          </div>
                          {/*
                          <div className="stops">
                            {flight.FareItinerary?.OriginDestinationOptions[1]
                              ?.TotalStops === 0
                              ? 'Direct'
                              : `${flight.FareItinerary?.OriginDestinationOptions[1]?.TotalStops} Stops`}
                          </div>
                          */}
                        </div>

                        <div className="returnArrivalInfo">
                          <div className="returnArrivalTime">
                            {new Date(
                              flight.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })}
                          </div>
                          <div className="returnArrivalAirportCode">
                            {
                              flight.FareItinerary?.OriginDestinationOptions[1]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.ArrivalAirportLocationCode
                            }
                          </div>
                        </div>

                        <div className="returnFlightClassInfo">
                          <div className="returnFlightClassTitle">Class</div>
                          <div className="returnFlightClassText">
                            {
                              flight.FareItinerary?.OriginDestinationOptions[1]
                                ?.OriginDestinationOption[0]?.FlightSegment
                                ?.CabinClassText
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="verticalLine"></div>

              {/* Price and Select Section */}
              <div className="priceSelect">
                <div className="price">
                  {'$'}
                  {
                    flight.FareItinerary?.AirItineraryFareInfo?.ItinTotalFares
                      ?.TotalFare?.Amount
                  }
                </div>
                <Button onClick={() => handleSelectFlight(flight)}>
                  Select
                </Button>
              </div>
            </div>
            <div
              className={`extraDetails ${expanded === index ? 'expanded' : ''}`}
            >
              <div className="basicInfo">
                <div>
                  Refundable:{' '}
                  {flight.FareItinerary?.AirItineraryFareInfo?.IsRefundable}
                </div>
                <div>
                  Fare Type:{' '}
                  {flight.FareItinerary?.AirItineraryFareInfo?.FareType}
                </div>
                <div
                  onClick={() => handleFareRulesClick(flight)}
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    textDecoration: 'underline',
                  }}
                >
                  Fare Rules
                </div>
                <Info
                  onClick={() => handleExpandClick(index)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {expanded === index && (
                <div>
                  {/* Display fare breakdown for all passengers */}
                  <div className="fareBreakdown">
                    <h4>Fare Breakdown</h4>
                    <table className="fareTable">
                      <thead>
                        <tr>
                          <th>Passenger Type</th>
                          <th>Total Fare</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flight.FareItinerary?.AirItineraryFareInfo?.FareBreakdown.map(
                          (fare, idx) => (
                            <tr key={idx}>
                              <td>{fare.PassengerTypeQuantity.Code}</td>
                              <td>
                                {fare.PassengerFare.TotalFare.Amount}{' '}
                                {fare.PassengerFare.TotalFare.CurrencyCode}
                              </td>
                              <td>{fare.PassengerTypeQuantity.Quantity}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Baggage Information */}
                  <div className="baggageInfo">
                    <h4>Baggage Information</h4>
                    {flight.FareItinerary?.AirItineraryFareInfo?.FareBreakdown.map(
                      (fare, idx) => (
                        <div key={idx} className="passengerBaggageInfo">
                          <h5>{fare.PassengerTypeQuantity.Code} Passenger</h5>
                          <ul>
                            {fare.Baggage.length > 0 && (
                              <li>
                                <strong>Baggage:</strong>{' '}
                                {fare.Baggage.join(', ')}
                              </li>
                            )}
                            {fare.CabinBaggage.length > 0 && (
                              <li>
                                <strong>Cabin Baggage:</strong>{' '}
                                {fare.CabinBaggage.join(', ')}
                              </li>
                            )}
                          </ul>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {/* Fare Rules Dialog */}
      <Dialog
        open={fareRulesOpen}
        onClose={handleFareRulesClose}
        aria-labelledby="fare-rules-dialog-title"
        className="fareRulesDialog"
      >
        <DialogTitle id="fare-rules-dialog-title">Fare Rules</DialogTitle>
        <DialogContent>
          {loadingFareRules ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress /> {/* Material-UI loading spinner */}
              <Typography>Loading fare rules...</Typography>
            </div>
          ) : selectedFlightFareRules && selectedFlightFareRules.length > 0 ? (
            <div>
              {selectedFlightFareRules.map((ruleObj, idx) => (
                <div key={idx} style={{ marginBottom: '10px' }}>
                  <Typography variant="h6">
                    Category: {ruleObj?.FareRule?.Category}
                  </Typography>
                  <Typography variant="subtitle2">
                    Airline: {ruleObj?.FareRule?.Airline}
                  </Typography>
                  <Typography variant="subtitle2">
                    Route: {ruleObj?.FareRule?.CityPair}
                  </Typography>
                  <Typography>
                    {ruleObj?.FareRule?.Rules || 'No rules available.'}
                  </Typography>
                </div>
              ))}
            </div>
          ) : (
            <Typography>No fare rules available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFareRulesClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FlightSelect;
