// Import necessary libraries and components for the flight search functionality
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  searchFlights,
  fetchAirportsList,
} from '../../../services/flightService';
import { useTranslation } from 'react-i18next'; // For internationalization
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JourneyTypeDropdown from '../../../components/journeyTypeDropdown/JourneyTypeDropdown';
import FlightClassDropdown from '../../../components/flightClassDropdown/FlightClassDropdown';
import PassengerSelector from '../../../components/passengerSelector/PassengerSelector';
import { TextField, Button } from '@mui/material';
import AutocompleteResults from '../../../components/autocompleteResults/AutocompleteResults';
import { useBooking } from '../../../contexts/BookingContext';
import './FlightSearch.scss'; // Styles specific to the FlightSearch component

// The FlightSearch component handles flight search form input and logic.
const FlightSearch = () => {
  const { t } = useTranslation(); // Internationalization hook
  const navigate = useNavigate(); // React Router hook to navigate between pages
  const { bookingData, updateBookingData, resetFlightResults } = useBooking(); // Custom hook to handle booking context

  // Initialize state for form data with default values.
  const [formData, setFormData] = useState({
    journeyType: 'OneWay', // Default to 'OneWay'
    origin: '', // Empty string for origin
    destination: '', // Empty string for destination
    departureDate: '',
    returnDate: '',
    class: 'Economy', // Default to 'Economy'
    adults: 1, // Default to 1 adult
    children: 0,
    infants: 0,
  });

  // Separate states to handle airport codes and airport data fetched via API
  const [airportOriginCode, setAirportOriginCode] = useState(
    bookingData.searchCriteria?.airportOriginCode || ''
  );
  const [airportDestinationCode, setAirportDestinationCode] = useState(
    bookingData.searchCriteria?.airportDestinationCode || ''
  );

  const [loading, setLoading] = useState(false); // Loading state for when fetching data
  const [errors, setErrors] = useState({}); // Object to hold form validation errors
  const [isPassengerDropdownOpen, setPassengerDropdownOpen] = useState(false); // Control the passenger dropdown
  const [airportOptions, setAirportOptions] = useState([]); // Store fetched airports list
  const [filteredAirports, setFilteredAirports] = useState([]); // Filtered airports based on user input
  const [searchTerm, setSearchTerm] = useState(''); // User's input for searching airports
  const [anchorEl, setAnchorEl] = useState(null); // Reference to the anchor element for autocomplete results

  const passengerDropdownRef = useRef(null); // Reference for passenger dropdown
  const autocompleteRef = useRef(null); // Reference for airport search autocomplete results

  // Fetch the list of airports once the component mounts
  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const airports = await fetchAirportsList(); // Fetch airports list via the API
        setAirportOptions(airports); // Save fetched airport list to state
      } catch (error) {
        console.error('Error fetching airports:', error); // Log any errors
      }
    };

    fetchAirports(); // Trigger fetching airports
  }, []);

  // Handle clicks outside the autocomplete dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setAnchorEl(null); // Close autocomplete results if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle form input change and perform validation for specific fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); // Update form data state
    setErrors({ ...errors, [name]: '' }); // Clear any existing errors for the changed field
    validateField(name, value); // Trigger validation for the current field
  };

  // Validate individual fields based on the input name and value
  // Validate individual fields based on the input name and value
  const validateField = (fieldName, value, index = null) => {
    const newErrors = { ...errors };
    const today = new Date().toISOString().split('T')[0]; // Get today's date in ISO format
    console.log(formData.journeyType);
    console.log(index);
    if (formData.journeyType === 'Multi-city' && index !== null) {
      console.log(fieldName);
      console.log(fieldName.startsWith('origin'));
      // Multi-city specific validation
      if (fieldName.startsWith('origin')) {
        console.log(value);
        console.log(multiCityLegs[index].origin);
        console.log(multiCityLegs[index].code);
        if (!value || !multiCityLegs[index].code) {
          console.log(1);
          newErrors[`origin${index}`] = t(
            'Please select a valid origin airport.'
          );
        } else {
          delete newErrors[`origin${index}`];
        }
      }

      if (fieldName.startsWith('destination')) {
        if (!value || !multiCityLegs[index].code) {
          newErrors[`destination${index}`] = t(
            'Please select a valid destination airport.'
          );
        } else {
          delete newErrors[`destination${index}`];
        }
      }

      if (fieldName.startsWith('departureDate')) {
        if (!value) {
          newErrors[`departureDate${index}`] = t('Departure date is required.');
        } else if (value < today) {
          newErrors[`departureDate${index}`] = t(
            'Departure date cannot be in the past.'
          );
        } else if (
          index > 0 &&
          value < multiCityLegs[index - 1].departureDate
        ) {
          newErrors[`departureDate${index}`] = t(
            'Departure date must be after the previous leg.'
          );
        } else {
          delete newErrors[`departureDate${index}`];
        }
      }
    } else {
      // Validation for OneWay/Return journeys
      switch (fieldName) {
        case 'origin':
          if (!value || !airportOriginCode) {
            newErrors.origin = t('Please select a valid origin airport.');
          } else {
            delete newErrors.origin;
          }
          break;

        case 'destination':
          if (!value || !airportDestinationCode) {
            newErrors.destination = t(
              'Please select a valid destination airport.'
            );
          } else {
            delete newErrors.destination;
          }
          break;

        case 'departureDate':
          if (!value) {
            newErrors.departureDate = t('Departure date is required.');
          } else if (value < today) {
            newErrors.departureDate = t(
              'Departure date cannot be in the past.'
            );
          } else {
            delete newErrors.departureDate;
          }
          break;

        case 'returnDate':
          if (formData.journeyType === 'Return') {
            if (!value) {
              newErrors.returnDate = t(
                'Return date is required for a round trip.'
              );
            } else if (value < formData.departureDate) {
              newErrors.returnDate = t(
                'Return date cannot be before departure date.'
              );
            } else {
              delete newErrors.returnDate;
            }
          }
          break;

        default:
          break;
      }
    }

    setErrors(newErrors); // Update error state with new or cleared errors
  };

  // Handle journey type change (OneWay, Return, etc.)
  const handleJourneyTypeChange = (journeyType) => {
    setFormData({ ...formData, journeyType });
    setErrors({ ...errors, journeyType: '' });
  };

  // Handle the change of flight class (Economy, Business, etc.)
  const handleClassChange = (flightClass) => {
    setFormData({ ...formData, class: flightClass });
    setErrors({ ...errors, class: '' });
  };

  // Handle changes to the passenger count
  const handlePassengersChange = (passengers) => {
    setFormData({ ...formData, ...passengers });
  };

  // Add this to your state initialization
  const [multiCityLegs, setMultiCityLegs] = useState([
    { origin: '', destination: '', departureDate: '' },
    { origin: '', destination: '', departureDate: '' },
  ]);

  // Function to add a new flight leg (row)
  const addFlightLeg = () => {
    setMultiCityLegs([
      ...multiCityLegs,
      { origin: '', destination: '', departureDate: '' },
    ]);
  };

  // Function to remove a flight leg
  const removeFlightLeg = (index) => {
    const updatedLegs = multiCityLegs.filter((_, i) => i !== index);
    setMultiCityLegs(updatedLegs);
  };

  // Function to handle change in a flight leg field (dynamic)
  const handleFlightLegChange = (index, field, value) => {
    const updatedLegs = [...multiCityLegs];
    updatedLegs[index][field] = value;
    setMultiCityLegs(updatedLegs);
  };

  // Ensure date validation: each leg's departure date must be after the previous leg
  const validateLegDates = () => {
    const newErrors = { ...errors };
    multiCityLegs.forEach((leg, index) => {
      if (!leg.departureDate) {
        newErrors[`departureDate${index}`] = t('Departure date is required.');
      } else if (
        index > 0 &&
        leg.departureDate < multiCityLegs[index - 1].departureDate
      ) {
        newErrors[`departureDate${index}`] = t(
          'Departure date must be after the previous leg.'
        );
      }
    });
    setErrors(newErrors);
  };

  // Full form validation when submitting the form
  const validate = () => {
    const newErrors = {};
    const today = new Date().toISOString().split('T')[0]; // Get current date in ISO format

    // Maps for journey types and flight classes
    const journeyTypeMap = { OneWay: 1, Return: 2, 'Multi-city': 3 };
    const flightClassMap = {
      Economy: 3,
      'Premium Economy': 4,
      Business: 2,
      First: 1,
    };

    // Validate based on journey type
    if (
      formData.journeyType === 'OneWay' ||
      formData.journeyType === 'Return'
    ) {
      // Existing validation logic for One-Way and Return
      if (!formData.origin || !airportOriginCode) {
        newErrors.origin = t('Please select a valid origin airport.');
      }

      if (!formData.destination || !airportDestinationCode) {
        newErrors.destination = t('Please select a valid destination airport.');
      }

      if (!formData.departureDate) {
        newErrors.departureDate = t('Departure date is required.');
      } else if (formData.departureDate < today) {
        newErrors.departureDate = t('Departure date cannot be in the past.');
      }

      if (formData.journeyType === 'Return') {
        if (!formData.returnDate) {
          newErrors.returnDate = t('Return date is required for a round trip.');
        } else if (formData.returnDate < formData.departureDate) {
          newErrors.returnDate = t(
            'Return date cannot be before departure date.'
          );
        }
      }
    } else if (formData.journeyType === 'Multi-city') {
      // Ensure at least two legs are provided
      if (multiCityLegs.length < 2) {
        newErrors.general = t(
          'At least two legs are required for a multi-city journey.'
        );
      }
      // Validation for multi-city journey
      multiCityLegs.forEach((leg, index) => {
        // Validate origin
        if (!leg.origin) {
          newErrors[`origin${index}`] = t(
            `Please select a valid origin airport for leg ${index + 1}.`
          );
        }

        // Validate destination
        if (!leg.destination) {
          newErrors[`destination${index}`] = t(
            `Please select a valid destination airport for leg ${index + 1}.`
          );
        }

        // Validate departure date
        const legDepartureDate = leg.departureDate;
        if (!legDepartureDate) {
          newErrors[`departureDate${index}`] = t(
            `Departure date is required for leg ${index + 1}.`
          );
        } else if (index === 0 && legDepartureDate < today) {
          // Ensure the first leg date is not in the past
          newErrors[`departureDate${index}`] = t(
            'Departure date cannot be in the past.'
          );
        } else if (
          index > 0 &&
          legDepartureDate < multiCityLegs[index - 1].departureDate
        ) {
          // Ensure each subsequent departure date is after the previous one
          newErrors[`departureDate${index}`] = t(
            `Departure date for leg ${index + 1} must be after leg ${index}.`
          );
        }
      });
    }

    setErrors(newErrors); // Set errors state

    // Return validation result and mapped values for journey type and flight class
    return {
      isValid: Object.keys(newErrors).length === 0,
      journeyTypeId: journeyTypeMap[formData.journeyType],
      flightClassId: flightClassMap[formData.class],
    };
  };

  // Handle the submission of the flight search form
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setErrors({}); // Clear any existing errors

    const { isValid, journeyTypeId, flightClassId } = validate(); // Validate form and get mapped IDs

    if (isValid) {
      let submissionData = {
        ...formData,
        journeyType: journeyTypeId,
        class: flightClassId,
      };

      if (journeyTypeId === 3) {
        // Multi-city case: include all flight legs
        submissionData.legs = multiCityLegs.map((leg) => ({
          origin: leg.origin,
          destination: leg.destination,
          departureDate: leg.departureDate,
        }));
      } else {
        // One-way or Return case
        submissionData.airportOriginCode = airportOriginCode;
        submissionData.airportDestinationCode = airportDestinationCode;
      }

      console.log('Form data on submit:', submissionData);

      // Store search criteria in the booking context but don't fetch flights yet
      await updateBookingData({ searchCriteria: submissionData });

      // Reset flight results to trigger a new search in SelectFlight
      resetFlightResults();

      // Navigate to the flight selection page immediately
      navigate('/flights/select');
    }
  };

  // Effect to log booking data updates
  useEffect(() => {
    if (bookingData.flightResults) {
      console.log('Booking data after update:', bookingData);
    }
  }, [bookingData]);

  const handleAirportSearch = (inputValue, fieldName, index = null) => {
    setSearchTerm(inputValue); // Store user's input in state

    if (inputValue.length >= 3) {
      const filtered = airportOptions.filter((airport) =>
        `${airport.AirportCode} ${airport.AirportName} ${airport.City}`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );

      const mappedResults = filtered.map((airport) => ({
        name: airport.AirportName,
        code: airport.AirportCode,
        cityCountry: `${airport.City}, ${airport.Country}`,
        type: airport.AirportName.includes('All Airports') ? 'city' : 'airport',
      }));

      setFilteredAirports(mappedResults); // Set filtered airports to be displayed

      // Add debug logs here
      console.log('Filtered Airports:', mappedResults);

      // If multi-city, use the dynamic field name with index, else handle one-way/return
      if (index !== null) {
        const element = document.querySelector(`[name="${fieldName}${index}"]`);
        if (element) {
          setAnchorEl({
            field: fieldName,
            index: index,
            element: element, // Ensure this is an HTML element
          });
        }
      } else {
        const element = document.querySelector(`[name="${fieldName}"]`);
        if (element) {
          setAnchorEl({
            field: fieldName,
            element: element, // Wrap the element in an object
          });
        }
      }
    } else {
      setFilteredAirports([]); // Clear the filtered airports if input is too short
      setAnchorEl(null);
    }
  };

  const handleSelectAirport = (result) => {
    const { field, index } = anchorEl || {}; // Check if multi-city or one-way/return

    // Log anchorEl to ensure it's set correctly
    console.log('Anchor Element:', anchorEl);

    // Copy the current errors state
    let newErrors = { ...errors };

    if (index !== undefined) {
      // Multi-city leg update
      const updatedLegs = [...multiCityLegs];
      updatedLegs[index][field] = {
        name: `${result.name} (${result.code})`,
        code: result.code,
      };
      setMultiCityLegs(updatedLegs);
      //console.log('WOW: ', newErrors[`${field}${index}`]);
      // Clear the error for the selected field (origin or destination)
      if (newErrors[`${field}${index}`]) {
        //console.log('WOW1: ', newErrors[`${field}${index}`]);
        delete newErrors[`${field}${index}`];
      }
    } else if (field) {
      console.log('Anchor Element:', anchorEl);
      console.log('field:', field);
      console.log('index:', index);
      // One-way/return journey update
      if (field === 'origin') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          origin: `${result.name} (${result.code})`,
        }));
        setAirportOriginCode(result.code); // Update the selected airport code for origin
        // Clear the origin error
        if (newErrors.origin) {
          delete newErrors.origin;
        }
      } else if (field === 'destination') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          destination: `${result.name} (${result.code})`,
        }));
        setAirportDestinationCode(result.code); // Update the selected airport code for destination
        // Clear the destination error
        if (newErrors.destination) {
          delete newErrors.destination;
        }
      }
    }
    setErrors(newErrors); // Update the errors state

    // Clear the autocomplete results and close the dropdown
    setFilteredAirports([]);
    setSearchTerm('');
    setAnchorEl(null);
  };

  // Calculate the total number of passengers for display
  const totalPassengers =
    formData.adults + formData.children + formData.infants;

  return (
    <div className="flight-search-container">
      <form className="flight-search-form" onSubmit={handleSubmit}>
        {/* Render dropdowns for selecting journey type and flight class */}
        <div className="search-options">
          <JourneyTypeDropdown
            selectedOption={formData.journeyType}
            onChange={handleJourneyTypeChange}
          />
          <FlightClassDropdown
            selectedClass={formData.class}
            onClassChange={handleClassChange}
          />

          {/* Passenger dropdown with toggle button */}
          <div className="dropdown" ref={passengerDropdownRef}>
            <button
              type="button"
              className="dropdown-toggle"
              onClick={() => setPassengerDropdownOpen(!isPassengerDropdownOpen)}
            >
              <PersonIcon />
              {totalPassengers}{' '}
              <span className="passenger-text">{t('Passenger')}</span>
              <ExpandMoreIcon />
            </button>
            {isPassengerDropdownOpen && (
              <PassengerSelector
                passengers={{
                  adults: formData.adults,
                  children: formData.children,
                  infants: formData.infants,
                }}
                onPassengersChange={handlePassengersChange}
              />
            )}
          </div>
        </div>

        {/* Multi-city specific section */}
        {formData.journeyType === 'Multi-city' && (
          <div className="multi-city-legs">
            {multiCityLegs.map((leg, index) => (
              <div key={index} className="input-group">
                <div className="autocomplete-container">
                  <TextField
                    label={t('From City, airport or place')}
                    name={`origin${index}`}
                    variant="outlined"
                    className="custom-textfield"
                    fullWidth
                    value={leg.origin?.name}
                    onChange={(e) => {
                      handleAirportSearch(e.target.value, 'origin', index); // Properly call the search function
                      handleFlightLegChange(index, 'origin', e.target.value);
                      validateField('origin', e.target.value, index); // Add validation here
                    }}
                    onFocus={() => {
                      setFilteredAirports([]);
                      setAnchorEl(
                        document.querySelector(`[name="origin${index}"]`)
                      );
                    }}
                    error={!!errors[`origin${index}`]} // Show error if it exists
                    helperText={errors[`origin${index}`]} // Display error message
                    required
                  />
                </div>

                <div className="autocomplete-container">
                  <TextField
                    label={t('To City, airport or place')}
                    name={`destination${index}`}
                    variant="outlined"
                    className="custom-textfield"
                    fullWidth
                    value={leg.destination?.name}
                    onFocus={() => {
                      setFilteredAirports([]);
                      setAnchorEl(
                        document.querySelector(`[name="destination${index}"]`)
                      );
                    }}
                    onChange={(e) => {
                      handleAirportSearch(e.target.value, 'destination', index); // Properly call the search function
                      handleFlightLegChange(
                        index,
                        'destination',
                        e.target.value
                      );
                      validateField('destination', e.target.value, index); // Add validation here
                    }}
                    error={!!errors[`destination${index}`]} // Show error if it exists
                    helperText={errors[`destination${index}`]} // Display error message
                    required
                  />
                </div>

                <TextField
                  label={t('Departure')}
                  name={`departureDate${index}`}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min:
                      index > 0
                        ? multiCityLegs[index - 1].departureDate
                        : new Date().toISOString().split('T')[0],
                  }}
                  value={leg.departureDate}
                  onChange={(e) => {
                    handleFlightLegChange(
                      index,
                      'departureDate',
                      e.target.value
                    );
                    validateField('departureDate', e.target.value, index); // Add validation here
                  }}
                  error={!!errors[`departureDate${index}`]} // Show error if it exists
                  helperText={errors[`departureDate${index}`]} // Display error message
                  variant="outlined"
                  className="custom-textfield"
                  fullWidth
                  required
                />

                {/* Show the remove button only if there are more than 2 legs */}
                {multiCityLegs.length > 2 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => removeFlightLeg(index)}
                  >
                    X
                  </Button>
                )}
              </div>
            ))}

            {/* Add button to add a new leg */}
            <Button variant="outlined" color="primary" onClick={addFlightLeg}>
              + {t('Add Destination')}
            </Button>
          </div>
        )}

        {/* Input fields for origin, destination, and dates for One-way or Return */}
        {formData.journeyType !== 'Multi-city' && (
          <div className="input-group">
            <div className="autocomplete-container">
              <TextField
                label={t('From City, airport or place')}
                name="origin"
                variant="outlined"
                className="custom-textfield"
                fullWidth
                error={!!errors.origin}
                helperText={errors.origin}
                onFocus={() => {
                  setFilteredAirports([]);
                  setAnchorEl(document.querySelector(`[name="origin"]`));
                }}
                onChange={(e) => {
                  handleAirportSearch(e.target.value, 'origin');
                  handleChange(e);
                }}
                value={formData.origin}
                required
              />
            </div>

            <div className="autocomplete-container">
              <TextField
                label={t('To City, airport or place')}
                name="destination"
                variant="outlined"
                className="custom-textfield"
                fullWidth
                error={!!errors.destination}
                helperText={errors.destination}
                onFocus={() => {
                  setFilteredAirports([]);
                  setAnchorEl(document.querySelector(`[name="destination"]`));
                }}
                onChange={(e) => {
                  handleAirportSearch(e.target.value, 'destination');
                  handleChange(e);
                }}
                value={formData.destination}
                required
              />
            </div>
          </div>
        )}

        {/* Display filtered airport suggestions in the autocomplete dropdown */}
        {filteredAirports.length > 0 && anchorEl && (
          <AutocompleteResults
            results={filteredAirports}
            searchTerm={searchTerm}
            anchorEl={anchorEl.element || anchorEl} // Use element or the plain anchorEl if set correctly
            onClose={() => setAnchorEl(null)}
            onSelect={handleSelectAirport}
            ref={autocompleteRef}
          />
        )}

        {/* Date pickers for departure and return dates */}
        {formData.journeyType !== 'Multi-city' && (
          <div className="input-group">
            <TextField
              label={t('Departure')}
              name="departureDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: new Date().toISOString().split('T')[0] }} // Prevent past date selection
              value={formData.departureDate}
              onChange={(e) => {
                handleChange(e);
                validateField('departureDate', e.target.value);
              }}
              variant="outlined"
              className="custom-textfield"
              fullWidth
              error={!!errors.departureDate}
              helperText={errors.departureDate}
              required
            />
            {formData.journeyType === 'Return' && (
              <TextField
                label={t('Return')}
                name="returnDate"
                type="date"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: formData.departureDate }} // Return date must be after departure date
                value={formData.returnDate}
                onChange={(e) => {
                  handleChange(e);
                  validateField('returnDate', e.target.value);
                }}
                variant="outlined"
                className="custom-textfield"
                fullWidth
                error={!!errors.returnDate}
                helperText={errors.returnDate}
                required
              />
            )}
          </div>
        )}

        {/* Submit button for searching flights */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className="explore-button"
          disabled={loading}
        >
          {loading ? t('Searching...') : t('Explore')}
        </Button>

        {errors.general && <p className="error-message">{errors.general}</p>}
      </form>
    </div>
  );
};

export default FlightSearch;
