import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBooking } from '../../../contexts/BookingContext';
import {
  completeBooking,
  fetchFareRules,
} from '../../../services/flightService';
import moment from 'moment'; // Import moment.js or similar library for date manipulation
import Swal from 'sweetalert2';
import './PassengerInfo.scss';
import {
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Flight, Info } from '@mui/icons-material';
import { countryCodes, nationalities } from '../../../data.ts';

const PassengerInfo = () => {
  const navigate = useNavigate();
  const { bookingData, updateBookingData } = useBooking();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false); // Add expanded state
  const [loadingFareRules, setLoadingFareRules] = useState(false);
  const [fareRulesOpen, setFareRulesOpen] = useState(false);
  const [selectedFlightFareRules, setSelectedFlightFareRules] = useState(null);

  const [passengerData, setPassengerData] = useState({
    passengers: [],
  });

  const [errors, setErrors] = useState({});

  const [selectedCountryCode, setSelectedCountryCode] = useState(''); // Holds the selected country code

  const handleCountryCodeSelect = (e) => {
    const code = e.target.value;
    setSelectedCountryCode(code);

    // Update the lead passenger (first passenger) with the selected country code
    const newPassengerData = [...passengerData.passengers];
    newPassengerData[0].countryCode = code; // Assuming the lead passenger is the first one (index 0)
    setPassengerData({ passengers: newPassengerData });
  };

  // Extracting necessary details for flight summary
  const selectedFlight = bookingData?.selectedFlight || {};

  const {
    adults = 0,
    children = 0,
    infants = 0,
  } = bookingData?.searchCriteria || {};

  const handleExpandClick = () => {
    setExpanded(!expanded); // Toggle expanded state
  };
  const handleFareRulesClick = async (flight) => {
    try {
      setLoadingFareRules(true);
      setFareRulesOpen(true); // Open dialog to show loading spinner

      const sessionId = bookingData?.session_id;
      const fareSourceCode =
        flight.FareItinerary?.AirItineraryFareInfo?.FareSourceCode;

      if (!sessionId) {
        throw new Error('Session ID is missing.');
      }

      // Fetch fare rules from backend API
      const response = await fetchFareRules(sessionId, fareSourceCode);

      const fareRules =
        response?.FareRules1_1Response?.FareRules1_1Result?.FareRules;

      if (!fareRules || !Array.isArray(fareRules)) {
        throw new Error('Invalid fare rules received from the backend.');
      }

      setSelectedFlightFareRules(fareRules);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error Occurred',
        text: error.message || 'Failed to fetch fare rules. Please try again.',
      });
    } finally {
      setLoadingFareRules(false); // Turn off loading indicator
    }
  };

  const handleFareRulesClose = () => {
    setFareRulesOpen(false);
    setSelectedFlightFareRules(null);
  };

  useEffect(() => {
    const generatePassengers = () => {
      let passengerList = [];
      let passengerCount = { Adult: 1, Child: 1, Infant: 1 };

      for (let i = 0; i < adults; i++) {
        passengerList.push({
          type: 'Adult',
          label: `Adult ${passengerCount.Adult++}`,
          title: '', // Add title field
          firstName: '',
          lastName: '',
          dob: '',
          passportNumber: '',
          passportExpiry: '',
          ...(i === 0 && { email: '', phoneNumber: '' }), // Only for lead passenger (Adult 1)
        });
      }

      for (let i = 0; i < children; i++) {
        passengerList.push({
          type: 'Child',
          label: `Child ${passengerCount.Child++}`,
          title: '', // Add title field
          firstName: '',
          lastName: '',
          dob: '',
          passportNumber: '',
          passportExpiry: '',
        });
      }

      for (let i = 0; i < infants; i++) {
        passengerList.push({
          type: 'Infant',
          label: `Infant ${passengerCount.Infant++}`,
          title: '', // Add title field
          firstName: '',
          lastName: '',
          dob: '',
          passportNumber: '', // Added passport number for infants
          passportExpiry: '', // Added passport expiry for infants
        });
      }
      setPassengerData({ passengers: passengerList });
    };

    generatePassengers();
  }, [adults, children, infants]);

  const validateInputs = () => {
    let newErrors = {};
    // Get the departure date based on journey type
    let departureDate;

    if (bookingData?.searchCriteria?.journeyType === 3) {
      // For journeyType 3, get the departure date from the first leg
      departureDate = moment(
        bookingData?.searchCriteria?.legs[0]?.departureDate
      );
    } else if (
      bookingData?.searchCriteria?.journeyType === 1 ||
      bookingData?.searchCriteria?.journeyType === 2
    ) {
      // For journeyType 1 or 2, get the departure date from the standard search criteria
      departureDate = moment(bookingData?.searchCriteria?.departureDate);
    } else {
      departureDate = null; // Handle case where journeyType is not defined or unknown
    }
    const nameRegex = /^[A-Za-z\s]+$/; // Regular expression to allow only letters

    passengerData.passengers.forEach((passenger, index) => {
      const isAdult = passenger.type === 'adult';
      const isChild = passenger.type === 'child';
      const isInfant = passenger.type === 'infant';

      // Email validation for lead passenger (Adult 1)
      if (index === 0) {
        // Email validation
        if (!passenger.email) {
          newErrors[`email_${index}`] = 'Email is required';
        } else if (
          !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
            passenger.email
          )
        ) {
          newErrors[`email_${index}`] = 'Invalid email format';
        }

        // Country code validation
        if (!passenger.countryCode) {
          newErrors[`countryCode_${index}`] = 'Country code is required';
        }

        // Phone number validation
        if (!passenger.phoneNumber) {
          newErrors[`phoneNumber_${index}`] = 'Phone number is required';
        } else if (!/^\d{8,}$/.test(passenger.phoneNumber)) {
          newErrors[`phoneNumber_${index}`] =
            'Phone number must be at least 8 digits long';
        }
      }
      // Title validation based on passenger type
      if (!passenger.title) {
        newErrors[`title_${index}`] = 'Title is required';
      } else if (isAdult && !['Mr', 'Mrs', 'Miss'].includes(passenger.title)) {
        newErrors[`title_${index}`] = 'Invalid title for an adult';
      } else if (
        (isChild || isInfant) &&
        !['Master', 'Miss'].includes(passenger.title)
      ) {
        newErrors[`title_${index}`] = 'Invalid title for a child or infant';
      }

      // First Name validation: Minimum 2 characters and only letters
      if (!passenger.firstName) {
        newErrors[`firstName_${index}`] = 'First Name is required';
      } else if (passenger.firstName.length < 2) {
        newErrors[`firstName_${index}`] =
          'First Name must be at least 2 characters long';
      } else if (!nameRegex.test(passenger.firstName)) {
        newErrors[`firstName_${index}`] =
          'First Name must contain only letters';
      }

      // Last Name validation: Minimum 2 characters and only letters
      if (!passenger.lastName) {
        newErrors[`lastName_${index}`] = 'Last Name is required';
      } else if (passenger.lastName.length < 2) {
        newErrors[`lastName_${index}`] =
          'Last Name must be at least 2 characters long';
      } else if (!nameRegex.test(passenger.lastName)) {
        newErrors[`lastName_${index}`] = 'Last Name must contain only letters';
      }

      // Date of Birth validation
      if (!passenger.dob) {
        newErrors[`dob_${index}`] = 'Date of Birth is required';
      } else {
        const dob = moment(passenger.dob);
        const ageOnDeparture = departureDate.diff(dob, 'years'); // Compare DOB to departure date

        // Age validation for main adult
        if (index === 0 && ageOnDeparture < 18) {
          newErrors[`dob_${index}`] =
            'Main adult passenger must be 18 years or older';
        } else if (index !== 0 && isAdult && ageOnDeparture < 12) {
          newErrors[`dob_${index}`] =
            'Adult passenger must be 12 years or older';
        } else if (isChild && (ageOnDeparture < 2 || ageOnDeparture >= 12)) {
          newErrors[`dob_${index}`] =
            'Child must be between 2 and 12 years old';
        } else if (isInfant && ageOnDeparture >= 2) {
          newErrors[`dob_${index}`] = 'Infant must be less than 2 years old';
        }
      }

      // Passport Number validation: Mandatory and must be at least 6 characters
      if (!passenger.passportNumber) {
        newErrors[`passportNumber_${index}`] = 'Passport Number is required';
      } else if (passenger.passportNumber.length < 6) {
        newErrors[`passportNumber_${index}`] =
          'Passport Number must be at least 6 characters long';
      }

      // Passport Expiry validation: Mandatory and must be valid for at least 3 months
      if (!passenger.passportExpiry) {
        newErrors[`passportExpiry_${index}`] = 'Passport Expiry is required';
      } else {
        const expiryDate = moment(passenger.passportExpiry);
        const monthsUntilExpiry = expiryDate.diff(departureDate, 'months'); // Compare expiry to departure date

        if (monthsUntilExpiry < 3) {
          newErrors[`passportExpiry_${index}`] =
            'Passport must be valid for at least 3 months beyond the departure date';
        }
      }

      // Nationality validation
      if (!passenger.nationality) {
        newErrors[`nationality_${index}`] = 'Nationality is required';
      }
    });

    // Update the state with validation errors
    setErrors(newErrors);

    // Return true if there are no errors, false otherwise
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (index, field, value) => {
    const newPassengerData = [...passengerData.passengers];
    newPassengerData[index][field] = value;
    setPassengerData({ passengers: newPassengerData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Passenger Data:', passengerData);

    // Run frontend validation before proceeding
    if (!validateInputs()) return;

    // Set loading to true when the submission starts
    setLoading(true);

    try {
      const flightId =
        bookingData?.selectedFlight?.FareItinerary?.AirItineraryFareInfo
          ?.FareSourceCode || 'Flight ID missing';
      const sessionId = bookingData?.session_id || 'Session ID missing';
      const fareSourceCode =
        bookingData?.fare_source_code || 'Fare Source Code missing';
      const fareType =
        bookingData?.selectedFlight?.FareItinerary?.AirItineraryFareInfo
          ?.FareType || 'Fare Type missing'; // Get the fare type
      const departurDate =
        bookingData?.searchCriteria?.departureDate || 'Departure Date missing';

      const response = await completeBooking(flightId, {
        session_id: sessionId,
        fare_source_code: fareSourceCode,
        fareType: fareType, // Include fareType in the payload
        passengerInfo: passengerData.passengers,
        departurDate: departurDate, // Include departure date in the payload
      });

      updateBookingData({
        passengers: passengerData.passengers,
        bookingConfirmation: response,
      });

      console.log('Booking completed:', response);
      // Handle the response depending on whether the booking was successful
      if (response.BookFlightResponse?.BookFlightResult?.Success) {
        navigate('/flights/ticket', {
          state: {
            status: response.BookFlightResponse.BookFlightResult.Status,
            uniqueID: response.BookFlightResponse.BookFlightResult.UniqueID,
            ticketNumber:
              response.BookFlightResponse.BookFlightResult.ticketNumber || null, // Optional
            ticketTimeLimit:
              response.BookFlightResponse.BookFlightResult.TktTimeLimit, // Optional for non-LCC
          },
        });
      }
    } catch (error) {
      console.error('Error completing booking:', error);

      // Check if the error contains the validation errors from the backend
      if (error.response && error.response.data && error.response.data.errors) {
        const backendErrors = error.response.data.errors;
        let newErrors = {};

        // Map backend errors to the frontend form fields
        backendErrors.forEach((err) => {
          // Extract the field and index from the error code
          const [field, index] = err.code.replace('_invalid', '').split('_');
          newErrors[`${field}_${index}`] = err.message;
        });

        setErrors(newErrors); // Set the validation errors in the state for form display
      } else {
        // Extract the error message based on the returned structure
        const errorMessage =
          error?.response?.data?.details?.error ||
          error?.response?.data?.details?.Errors?.ErrorMessage ||
          'An unknown error occurred';
        Swal.fire({
          icon: 'error',
          title: 'Booking Error',
          text: errorMessage,
          showCancelButton: true, // Show "Cancel" button which will serve as the "OK" button
          confirmButtonText: 'Search Again', // This is the button that navigates back to search
          cancelButtonText: 'OK', // This keeps the user on the same page
        }).then((result) => {
          if (result.isConfirmed) {
            // If "Search Again" is clicked
            navigate('/flights/search'); // Redirect to the flight search page
          }
          // If "OK" is clicked, do nothing, stay on the current page
        });
      }
    } finally {
      // Set loading to false after the API call completes (either success or failure)
      setLoading(false);
    }
  };

  if (!bookingData || !bookingData.searchCriteria) {
    return <div>Error: Booking data or search criteria are missing.</div>;
  }

  return (
    <div className="passenger-info">
      <h1>Passenger Information</h1>
      {/* Show the loading spinner above the form */}
      {loading && (
        <div className="loading-container">
          <CircularProgress />
          <Typography variant="h6" className="loading-text">
            Booking in progess...
          </Typography>
        </div>
      )}
      <div className="flight-details">
        <div className="flightCardContainer">
          <div className="flightCard">
            <div className="flightInfo">
              {/* One-way flight info */}
              <div className="oneWay">
                <h3>
                  Outbound Flight {'( '}
                  {bookingData?.selectedFlight?.FareItinerary
                    ?.OriginDestinationOptions[0]?.TotalStops === 0
                    ? 'Direct'
                    : `${bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[0]?.TotalStops} Stops`}{' '}
                  {' )'}
                </h3>
                {bookingData?.selectedFlight?.FareItinerary
                  ?.OriginDestinationOptions[0]?.OriginDestinationOption
                  .length > 1 ? (
                  bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption.map(
                    (segment, index) => (
                      <div key={index} className="flightSegment">
                        <div className="airline-logo-container">
                          <img
                            src={`/airlines/${segment.FlightSegment?.MarketingAirlineCode}.png`}
                            alt="Airline Logo"
                            onError={(e) => {
                              e.target.onerror = null; // Prevents infinite looping if the fallback image also fails
                              e.target.style.display = 'none'; // Hide the image
                              e.target.parentNode.querySelector(
                                '.airline-code'
                              ).style.display = 'block'; // Show airline code
                            }}
                          />
                          <div
                            className="airline-code"
                            style={{ display: 'none' }}
                          >
                            {segment.FlightSegment?.MarketingAirlineCode}
                          </div>
                        </div>

                        <div className="departureInfo">
                          <div className="departureTime">
                            {new Date(
                              segment.FlightSegment?.DepartureDateTime
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })}
                          </div>
                          <div className="departureAirportCode">
                            {
                              segment.FlightSegment
                                ?.DepartureAirportLocationCode
                            }
                          </div>
                        </div>

                        <div className="metaInfo">
                          <div className="flightDuration">
                            {Math.floor(
                              segment.FlightSegment?.JourneyDuration / 60
                            )}
                            h {segment.FlightSegment?.JourneyDuration % 60}m
                          </div>
                          <div className="connectingLine">
                            <Flight className="flight-icon" />
                          </div>
                        </div>

                        <div className="arrivalInfo">
                          <div className="arrivalTime">
                            {new Date(
                              segment.FlightSegment?.ArrivalDateTime
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })}
                          </div>
                          <div className="arrivalAirportCode">
                            {segment.FlightSegment?.ArrivalAirportLocationCode}
                          </div>
                        </div>

                        <div className="flightClassInfo">
                          <div className="flightClassTitle">Class</div>
                          <div className="flightClassText">
                            {segment.FlightSegment?.CabinClassText}
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  // Direct flight
                  <div className="flightSegment">
                    <div className="airline-logo-container">
                      <img
                        src={`/airlines/${bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.MarketingAirlineCode}.png`}
                        alt="Airline Logo"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.style.display = 'none';
                          e.target.parentNode.querySelector(
                            '.airline-code'
                          ).style.display = 'block';
                        }}
                      />
                      <div className="airline-code" style={{ display: 'none' }}>
                        {
                          bookingData?.selectedFlight?.FareItinerary
                            ?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.MarketingAirlineCode
                        }
                      </div>
                    </div>

                    <div className="departureInfo">
                      <div className="departureTime">
                        {new Date(
                          bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                        ).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })}
                      </div>
                      <div className="departureAirportCode">
                        {
                          bookingData?.selectedFlight?.FareItinerary
                            ?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.DepartureAirportLocationCode
                        }
                      </div>
                    </div>

                    <div className="metaInfo">
                      <div className="flightDuration">
                        {Math.floor(
                          bookingData?.selectedFlight?.FareItinerary
                            ?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.JourneyDuration / 60
                        )}
                        h{' '}
                        {bookingData?.selectedFlight?.FareItinerary
                          ?.OriginDestinationOptions[0]
                          ?.OriginDestinationOption[0]?.FlightSegment
                          ?.JourneyDuration % 60}
                        m
                      </div>
                      <div className="connectingLine">
                        <Flight className="flight-icon" />
                      </div>
                    </div>

                    <div className="arrivalInfo">
                      <div className="arrivalTime">
                        {new Date(
                          bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                        ).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })}
                      </div>
                      <div className="arrivalAirportCode">
                        {
                          bookingData?.selectedFlight?.FareItinerary
                            ?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.ArrivalAirportLocationCode
                        }
                      </div>
                    </div>

                    <div className="flightClassInfo">
                      <div className="flightClassTitle">Class</div>
                      <div className="flightClassText">
                        {
                          bookingData?.selectedFlight?.FareItinerary
                            ?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.CabinClassText
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Return flight info (if applicable) */}
              {bookingData?.selectedFlight?.FareItinerary?.DirectionInd ===
                'Return' && (
                <div className="return">
                  <h3>
                    Inbound Flight {'( '}
                    {bookingData?.selectedFlight?.FareItinerary
                      ?.OriginDestinationOptions[1]?.TotalStops === 0
                      ? 'Direct'
                      : `${bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[1]?.TotalStops} Stops`}{' '}
                    {' )'}
                  </h3>
                  {bookingData?.selectedFlight?.FareItinerary
                    ?.OriginDestinationOptions[1]?.OriginDestinationOption
                    .length > 1 ? (
                    bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption.map(
                      (segment, index) => (
                        <div key={index} className="returnFlightSegment">
                          <div className="airline-logo-container">
                            <img
                              src={`/airlines/${segment.FlightSegment?.MarketingAirlineCode}.png`}
                              alt="Airline Logo"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.style.display = 'none';
                                e.target.parentNode.querySelector(
                                  '.airline-code'
                                ).style.display = 'block';
                              }}
                            />
                            <div
                              className="airline-code"
                              style={{ display: 'none' }}
                            >
                              {segment.FlightSegment?.MarketingAirlineCode}
                            </div>
                          </div>

                          <div className="returnDepartureInfo">
                            <div className="returnDepartureTime">
                              {new Date(
                                segment.FlightSegment?.DepartureDateTime
                              ).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                              })}
                            </div>
                            <div className="returnDepartureAirportCode">
                              {
                                segment.FlightSegment
                                  ?.DepartureAirportLocationCode
                              }
                            </div>
                          </div>

                          <div className="metaInfo">
                            <div className="flightDuration">
                              {Math.floor(
                                segment.FlightSegment?.JourneyDuration / 60
                              )}
                              h {segment.FlightSegment?.JourneyDuration % 60}m
                            </div>
                            <div className="connectingLine">
                              <Flight className="flight-icon" />
                            </div>
                          </div>

                          <div className="returnArrivalInfo">
                            <div className="returnArrivalTime">
                              {new Date(
                                segment.FlightSegment?.ArrivalDateTime
                              ).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                              })}
                            </div>
                            <div className="returnArrivalAirportCode">
                              {
                                segment.FlightSegment
                                  ?.ArrivalAirportLocationCode
                              }
                            </div>
                          </div>

                          <div className="returnFlightClassInfo">
                            <div className="returnFlightClassTitle">Class</div>
                            <div className="returnFlightClassText">
                              {segment.FlightSegment?.CabinClassText}
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    // Direct return flight
                    <div className="returnFlightSegment">
                      <div className="airline-logo-container">
                        <img
                          src={`/airlines/${bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.MarketingAirlineCode}.png`}
                          alt="Airline Logo"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.style.display = 'none';
                            e.target.parentNode.querySelector(
                              '.airline-code'
                            ).style.display = 'block';
                          }}
                        />
                        <div
                          className="airline-code"
                          style={{ display: 'none' }}
                        >
                          {
                            bookingData?.selectedFlight?.FareItinerary
                              ?.OriginDestinationOptions[1]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.MarketingAirlineCode
                          }
                        </div>
                      </div>

                      <div className="returnDepartureInfo">
                        <div className="returnDepartureTime">
                          {new Date(
                            bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          })}
                        </div>
                        <div className="returnDepartureAirportCode">
                          {
                            bookingData?.selectedFlight?.FareItinerary
                              ?.OriginDestinationOptions[1]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.DepartureAirportLocationCode
                          }
                        </div>
                      </div>

                      <div className="metaInfo">
                        <div className="flightDuration">
                          {Math.floor(
                            bookingData?.selectedFlight?.FareItinerary
                              ?.OriginDestinationOptions[1]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.JourneyDuration / 60
                          )}
                          h{' '}
                          {bookingData?.selectedFlight?.FareItinerary
                            ?.OriginDestinationOptions[1]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.JourneyDuration % 60}
                          m
                        </div>
                        <div className="connectingLine">
                          <Flight className="flight-icon" />
                        </div>
                      </div>

                      <div className="returnArrivalInfo">
                        <div className="returnArrivalTime">
                          {new Date(
                            bookingData?.selectedFlight?.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          })}
                        </div>
                        <div className="returnArrivalAirportCode">
                          {
                            bookingData?.selectedFlight?.FareItinerary
                              ?.OriginDestinationOptions[1]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.ArrivalAirportLocationCode
                          }
                        </div>
                      </div>

                      <div className="returnFlightClassInfo">
                        <div className="returnFlightClassTitle">Class</div>
                        <div className="returnFlightClassText">
                          {
                            bookingData?.selectedFlight?.FareItinerary
                              ?.OriginDestinationOptions[1]
                              ?.OriginDestinationOption[0]?.FlightSegment
                              ?.CabinClassText
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="verticalLine"></div>

            {/* Price and Fare Breakdown (Optional) */}
            <div className="priceSelect">
              <div className="price">
                {'$'}
                {
                  bookingData?.selectedFlight?.FareItinerary
                    ?.AirItineraryFareInfo?.ItinTotalFares?.TotalFare?.Amount
                }
              </div>
            </div>
          </div>
          {/* Extra Details Section */}
          <div className={`extraDetails ${expanded ? 'expanded' : ''}`}>
            <div className="basicInfo">
              <div>
                Refundable:{' '}
                {
                  selectedFlight.FareItinerary?.AirItineraryFareInfo
                    ?.IsRefundable
                }
              </div>
              <div>
                Fare Type:{' '}
                {selectedFlight.FareItinerary?.AirItineraryFareInfo?.FareType}
              </div>
              <div
                onClick={() => handleFareRulesClick(selectedFlight)}
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                  textDecoration: 'underline',
                }}
              >
                Fare Rules
              </div>
              <Info onClick={handleExpandClick} style={{ cursor: 'pointer' }} />
            </div>

            {expanded && (
              <div>
                {/* Display fare breakdown for all passengers */}
                <div className="fareBreakdown">
                  <h4>Fare Breakdown</h4>
                  <table className="fareTable">
                    <thead>
                      <tr>
                        <th>Passenger Type</th>
                        <th>Total Fare</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedFlight.FareItinerary?.AirItineraryFareInfo?.FareBreakdown.map(
                        (fare, idx) => (
                          <tr key={idx}>
                            <td>{fare.PassengerTypeQuantity.Code}</td>
                            <td>
                              {fare.PassengerFare.TotalFare.Amount}{' '}
                              {fare.PassengerFare.TotalFare.CurrencyCode}
                            </td>
                            <td>{fare.PassengerTypeQuantity.Quantity}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                {/* Baggage Information */}
                <div className="baggageInfo">
                  <h4>Baggage Information</h4>
                  {selectedFlight.FareItinerary?.AirItineraryFareInfo?.FareBreakdown.map(
                    (fare, idx) => (
                      <div key={idx} className="passengerBaggageInfo">
                        <h5>{fare.PassengerTypeQuantity.Code} Passenger</h5>
                        <ul>
                          {fare.Baggage.length > 0 && (
                            <li>
                              <strong>Baggage:</strong>{' '}
                              {fare.Baggage.join(', ')}
                            </li>
                          )}
                          {fare.CabinBaggage.length > 0 && (
                            <li>
                              <strong>Cabin Baggage:</strong>{' '}
                              {fare.CabinBaggage.join(', ')}
                            </li>
                          )}
                        </ul>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Fare Rules Dialog */}
        <Dialog open={fareRulesOpen} onClose={() => setFareRulesOpen(false)}>
          <DialogTitle>Fare Rules</DialogTitle>
          <DialogContent>
            {loadingFareRules ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress /> {/* Material-UI loading spinner */}
                <Typography>Loading fare rules...</Typography>
              </div>
            ) : selectedFlightFareRules &&
              selectedFlightFareRules.length > 0 ? (
              <div>
                {selectedFlightFareRules.map((ruleObj, idx) => (
                  <div key={idx} style={{ marginBottom: '10px' }}>
                    <Typography variant="h6">
                      Category: {ruleObj?.FareRule?.Category}
                    </Typography>
                    <Typography variant="subtitle2">
                      Airline: {ruleObj?.FareRule?.Airline}
                    </Typography>
                    <Typography variant="subtitle2">
                      Route: {ruleObj?.FareRule?.CityPair}
                    </Typography>
                    <Typography>
                      {ruleObj?.FareRule?.Rules || 'No rules available.'}
                    </Typography>
                  </div>
                ))}
              </div>
            ) : (
              <Typography>No fare rules available.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFareRulesClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <form onSubmit={handleSubmit}>
        {passengerData.passengers.map((passenger, index) => (
          <div className="passenger-row" key={index}>
            <h3>{passenger.label}</h3>
            <div className="passenger-fields">
              <div className="form-group">
                <label>Title</label>
                <select
                  value={passenger.title}
                  onChange={(e) =>
                    handleInputChange(index, 'title', e.target.value)
                  }
                >
                  <option value="">Select Title</option>
                  {passenger.type === 'Adult' ? (
                    <>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                    </>
                  ) : (
                    <>
                      <option value="Master">Master</option>
                      <option value="Miss">Miss</option>
                    </>
                  )}
                </select>
                {errors[`title_${index}`] && (
                  <span className="error-text">{errors[`title_${index}`]}</span>
                )}
              </div>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  value={passenger.firstName}
                  onChange={(e) =>
                    handleInputChange(index, 'firstName', e.target.value)
                  }
                />
                {errors[`firstName_${index}`] && (
                  <span className="error-text">
                    {errors[`firstName_${index}`]}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={passenger.lastName}
                  onChange={(e) =>
                    handleInputChange(index, 'lastName', e.target.value)
                  }
                />
                {errors[`lastName_${index}`] && (
                  <span className="error-text">
                    {errors[`lastName_${index}`]}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Date of Birth</label>
                <input
                  type="date"
                  value={passenger.dob}
                  onChange={(e) =>
                    handleInputChange(index, 'dob', e.target.value)
                  }
                />
                {errors[`dob_${index}`] && (
                  <span className="error-text">{errors[`dob_${index}`]}</span>
                )}
              </div>
              <div className="form-group">
                <label>Passport Number</label>
                <input
                  type="text"
                  value={passenger.passportNumber}
                  onChange={(e) =>
                    handleInputChange(index, 'passportNumber', e.target.value)
                  }
                />
                {errors[`passportNumber_${index}`] && (
                  <span className="error-text">
                    {errors[`passportNumber_${index}`]}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Passport Expiry Date</label>
                <input
                  type="date"
                  value={passenger.passportExpiry}
                  onChange={(e) =>
                    handleInputChange(index, 'passportExpiry', e.target.value)
                  }
                  min={moment().format('YYYY-MM-DD')} // Set the minimum date to today
                />
                {errors[`passportExpiry_${index}`] && (
                  <span className="error-text">
                    {errors[`passportExpiry_${index}`]}
                  </span>
                )}
              </div>
              {index === 0 && (
                <>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={passenger.email}
                      onChange={(e) =>
                        handleInputChange(index, 'email', e.target.value)
                      }
                    />
                    {errors[`email_${index}`] && (
                      <span className="error-text">
                        {errors[`email_${index}`]}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Country Code</label>
                    <select
                      value={passengerData.passengers[0]?.countryCode || ''} // Bind to lead passenger's country code
                      onChange={handleCountryCodeSelect} // Handle change and update passengerData
                      required
                    >
                      <option value="" disabled>
                        Select Country Code
                      </option>
                      {countryCodes.map((country, idx) => (
                        <option key={idx} value={country.code}>
                          ( {country.code} ) {country.name}
                        </option>
                      ))}
                    </select>
                    {errors[`countryCode_${index}`] && (
                      <span className="error-text">
                        {errors[`countryCode_${index}`]}
                      </span>
                    )}
                  </div>

                  {/* Input field showing only the selected country code */}
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      placeholder="Enter phone number"
                      value={passenger.phoneNumber}
                      onChange={(e) =>
                        handleInputChange(index, 'phoneNumber', e.target.value)
                      }
                      required
                    />
                    {errors[`phoneNumber_${index}`] && (
                      <span className="error-text">
                        {errors[`phoneNumber_${index}`]}
                      </span>
                    )}
                  </div>
                </>
              )}

              <div className="form-group">
                <label>Nationality</label>
                <select
                  value={passenger.nationality}
                  onChange={(e) =>
                    handleInputChange(index, 'nationality', e.target.value)
                  }
                  required
                >
                  <option value="">Select Nationality</option>
                  {nationalities.map((nat, idx) => (
                    <option key={idx} value={nat.code}>
                      {nat.name}
                    </option>
                  ))}
                </select>
                {errors[`nationality_${index}`] && (
                  <span className="error-text">
                    {errors[`nationality_${index}`]}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default PassengerInfo;
